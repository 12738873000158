import React, { useState, useEffect } from 'react';
import { 
 
  FaChevronDown 
} from 'react-icons/fa';
import Select from 'react-select';
import { supabase } from '../../utils/supabaseClient';

const Informes = () => {
  const [clientesAtrasados, setClientesAtrasados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filtroTiempo, setFiltroTiempo] = useState('7');
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [estadoFiltro, setEstadoFiltro] = useState('todos');
  const [clientesAtrasadosState, setClientesAtrasadosState] = useState([]);

  const ESTADOS = {
    PENDIENTE: 'Pendiente',
    NOTIFICADO: 'Notificado',
    EN_NEGOCIACION: 'En negociación',
    PLAN_PAGO: 'Plan de pago',
    RECLAMACION_LEGAL: 'Reclamación legal',
    INCOBRABLE: 'Incobrable',
    PAGADO: 'Pagado'
  };

  const estadisticas = {
    totalImpagado: clientesAtrasados.reduce((sum, cliente) => sum + cliente.importePendiente, 0),
    totalClientes: clientesAtrasados.length,
    clientesPendientes: clientesAtrasados.filter(c => c.estado === 'Pendiente').length
  };

  const getEstadoColor = (estado) => {
    switch (estado) {
      case ESTADOS.PENDIENTE: return 'bg-red-100 text-red-800';
      case ESTADOS.NOTIFICADO: return 'bg-blue-100 text-blue-800';
      case ESTADOS.EN_NEGOCIACION: return 'bg-yellow-100 text-yellow-800';
      case ESTADOS.PLAN_PAGO: return 'bg-purple-100 text-purple-800';
      case ESTADOS.RECLAMACION_LEGAL: return 'bg-orange-100 text-orange-800';
      case ESTADOS.INCOBRABLE: return 'bg-gray-100 text-gray-800';
      case ESTADOS.PAGADO: return 'bg-green-100 text-green-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const estadosOptions = Object.entries(ESTADOS).map(([key, value]) => ({
    value: value,
    label: (
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2">
          <span className={`w-3 h-3 rounded-full ${getEstadoColor(value).replace('text-', 'bg-')}`}></span>
          <span>{value}</span>
        </div>
      </div>
    ),
    customStyles: getEstadoColor(value)
  }));

  const handleEstadoChange = (selectedOption, clienteId) => {
    setClientesAtrasadosState(prevClientes =>
      prevClientes.map(cliente =>
        cliente.id === clienteId
          ? { ...cliente, estado: selectedOption.value }
          : cliente
      )
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: '8px 12px',
      cursor: 'pointer',
      backgroundColor: state.isSelected ? state.data.customStyles.split(' ')[0] : 'white',
      color: state.isSelected ? state.data.customStyles.split(' ')[1] : 'black',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: state.data.customStyles.split(' ')[0],
        color: state.data.customStyles.split(' ')[1],
        opacity: 0.8
      },
      '&:after': state.isSelected ? {
        content: '"✓"',
        marginLeft: '8px',
        fontWeight: 'bold'
      } : {},
      fontWeight: state.isSelected ? '600' : '400',
    }),
    control: (provided, state) => ({
      ...provided,
      minWidth: '200px',
      border: state.isFocused 
        ? `2px solid ${state.selectProps.value?.customStyles?.split(' ')[1] || '#6366f1'}`
        : '1px solid #e2e8f0',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      backgroundColor: state.selectProps.value?.customStyles?.split(' ')[0] || 'white',
      padding: '2px',
      transition: 'all 0.2s ease',
      '&:hover': {
        borderColor: state.selectProps.value?.customStyles?.split(' ')[1] || '#cbd5e1'
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.selectProps.value?.customStyles?.split(' ')[1] || 'black',
      fontWeight: '600'
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      borderRadius: '0.375rem',
      marginTop: '4px',
      border: '1px solid #e2e8f0'
    })
  };

  const columnas = [
    {
      title: 'Cliente',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
    },
    {
      title: 'Cuotas Impagadas',
      dataIndex: 'cuotasImpagadas',
      key: 'cuotasImpagadas',
      render: (cuotas, record) => (
        <button
          onClick={() => setSelectedClient(record)}
          className="text-purple-600 hover:text-purple-800 underline cursor-pointer"
        >
          {cuotas} cuotas
        </button>
      ),
    },
    {
      title: 'Importe impagado',
      dataIndex: 'importePendiente',
      key: 'importePendiente',
      render: (importe) => `€${importe.toFixed(2)}`,
    },
    {
      title: 'Total Honorarios',
      dataIndex: 'totalHonorarios',
      key: 'totalHonorarios',
      render: (importe) => `€${importe?.toFixed(2) || '0.00'}`,
    },
    {
      title: 'Total Pagado',
      dataIndex: 'totalPagado',
      key: 'totalPagado',
      render: (importe) => `€${importe?.toFixed(2) || '0.00'}`,
    },
    {
      title: 'Falta por Pagar',
      key: 'faltaPorPagar',
      render: (_, record) => {
        const pendiente = (record.totalHonorarios || 0) - (record.totalPagado || 0);
        return `€${pendiente.toFixed(2)}`;
      },
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      render: (estado, record) => {
        const selectedOption = estadosOptions.find(option => option.value === estado) || estadosOptions[0];
        
        return (
          <Select
            value={selectedOption}
            onChange={(option) => handleEstadoChange(option, record.id)}
            options={estadosOptions}
            styles={customStyles}
            isSearchable={false}
            className="w-full"
            classNamePrefix="select"
            defaultValue={estadosOptions[0]}
          />
        );
      },
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (_, record) => (
        <button 
          onClick={() => reclamarPago(record)}
          disabled={record.estado === 'Pagado'}
          className={`px-4 py-2 rounded-md text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed`}
        >
          Reclamar Pago
        </button>
      ),
    },
  ];

  const reclamarPago = (cliente) => {
    // Lógica para enviar el email y crear la tarea
    alert(`Reclamación enviada a ${cliente.nombre}`);
  };

  const DetallesCuotasModal = ({ cliente, onClose }) => {
    if (!cliente) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg p-6 max-w-lg w-full">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold">Detalles de Cuotas - {cliente.nombre}</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg mb-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500">Total Honorarios</p>
                <p className="text-lg font-semibold">€{cliente.totalHonorarios.toFixed(2)}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Total Pagado</p>
                <p className="text-lg font-semibold">€{cliente.totalPagado.toFixed(2)}</p>
              </div>
            </div>
          </div>

          <div className="border rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Fecha Prevista</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Cuantía</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {cliente.detallesCuotas?.map((cuota, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2">{new Date(cuota.fecha).toLocaleDateString()}</td>
                    <td className="px-4 py-2">€{cuota.cuantia.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const fetchImpagados = async () => {
    try {
      console.log('Iniciando fetchImpagados...');
      
      // Obtener la fecha de hoy
      const hoy = new Date();
      hoy.setHours(0, 0, 0, 0); // Asegurarse de que la hora sea 00:00:00

      // Fetch cuotas data
      const cuotasResponse = await supabase
        .from('cuotas')
        .select('id, cuantia, fecha_prevista, estado, cliente_id')
        .eq('estado', 'pendiente');

      console.log('Respuesta completa de cuotas:', cuotasResponse);
      
      if (cuotasResponse.error) {
        console.error('Error en cuotas:', cuotasResponse.error);
        throw cuotasResponse.error;
      }

      if (!cuotasResponse.data || cuotasResponse.data.length === 0) {
        console.log('No se encontraron cuotas impagadas');
        setClientesAtrasados([]);
        setClientesAtrasadosState([]);
        return;
      }

      // Filtrar cuotas que tienen fecha prevista hasta hoy
      const cuotasData = cuotasResponse.data.filter(cuota => {
        const fechaPrevista = new Date(cuota.fecha_prevista);
        return fechaPrevista <= hoy;
      });

      console.log('Número de cuotas encontradas hasta hoy:', cuotasData.length);

      // Fetch cliente data
      const clienteIds = cuotasData.map(c => c.cliente_id);
      console.log('IDs de clientes a buscar:', clienteIds);

      const clientesResponse = await supabase
        .from('clientes')
        .select('id, nombre, apellidos, telefono, email')
        .in('id', clienteIds);

      if (clientesResponse.error) {
        console.error('Error en clientes:', clientesResponse.error);
        throw clientesResponse.error;
      }

      const clientesData = clientesResponse.data;
      console.log('Respuesta completa de clientes:', clientesData);

      // Fetch plan_honorarios data
      const { data: honorariosData, error: honorariosError } = await supabase
        .from('plan_honorarios')
        .select('user_id, total_honorarios, total_pagado')
        .in('user_id', clienteIds);

      if (honorariosError) throw honorariosError;
      console.log('Datos de honorarios:', honorariosData);

      // Agrupamos y transformamos los datos
      const clientesAgrupados = cuotasData.reduce((acc, cuota) => {
        const cliente = clientesData.find(c => c.id === cuota.cliente_id);
        const planHonorarios = honorariosData.find(h => h.user_id === cuota.cliente_id);
        const clienteId = cuota.cliente_id;

        if (!acc[clienteId] && cliente) {
          acc[clienteId] = {
            cliente_id: clienteId,
            nombre: cliente.nombre,
            apellidos: cliente.apellidos,
            telefono: cliente.telefono,
            email: cliente.email,
            total_debe: 0,
            total_honorarios: planHonorarios?.total_honorarios || 0,
            total_pagado: planHonorarios?.total_pagado || 0,
            cuotas_impagadas: 0,
            fechas_previstas_pago: [],
            importes_cuotas_pendientes: []
          };
        }

        if (acc[clienteId]) {
          // Sumamos los valores
          acc[clienteId].total_debe += cuota.cuantia;
          acc[clienteId].cuotas_impagadas += 1;
          acc[clienteId].fechas_previstas_pago.push(cuota.fecha_prevista);
          acc[clienteId].importes_cuotas_pendientes.push(cuota.cuantia);
        }

        return acc;
      }, {});

      // Convertimos el objeto en array y calculamos la diferencia
      const clientesFormateados = Object.values(clientesAgrupados).map(cliente => ({
        id: cliente.cliente_id,
        nombre: `${cliente.nombre} ${cliente.apellidos}`,
        telefono: cliente.telefono,
        correo: cliente.email,
        cuotasImpagadas: cliente.cuotas_impagadas,
        importePendiente: cliente.total_debe,
        estado: 'Pendiente',
        totalHonorarios: cliente.total_honorarios,
        totalPagado: cliente.total_pagado,
        diferencia: cliente.total_honorarios - cliente.total_pagado,
        detallesCuotas: cliente.fechas_previstas_pago.map((fecha, index) => ({
          fecha: fecha,
          cuantia: cliente.importes_cuotas_pendientes[index]
        }))
      }));

      // Add log for final formatted data
      console.log('Datos formateados finales:', clientesFormateados);

      setClientesAtrasados(clientesFormateados);
      setClientesAtrasadosState(clientesFormateados);
    } catch (error) {
      console.error('Error detallado al cargar los impagados:', {
        message: error.message,
        stack: error.stack,
        error
      });
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImpagados();
  }, []);

  return (
    <div className="p-6">
      {loading ? (
        <div className="text-center">Cargando...</div>
      ) : error ? (
        <div className="text-red-600">Error: {error}</div>
      ) : (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-6">Gestión de Pagos Atrasados</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="bg-purple-50 p-4 rounded-lg">
              <p className="text-sm text-purple-600">Total Impagado</p>
              <p className="text-2xl font-bold text-purple-800">€{estadisticas.totalImpagado.toFixed(2)}</p>
            </div>
            <div className="bg-blue-50 p-4 rounded-lg">
              <p className="text-sm text-blue-600">Total Clientes</p>
              <p className="text-2xl font-bold text-blue-800">{estadisticas.totalClientes}</p>
            </div>
            <div className="bg-red-50 p-4 rounded-lg">
              <p className="text-sm text-red-600">Clientes Pendientes</p>
              <p className="text-2xl font-bold text-red-800">{estadisticas.clientesPendientes}</p>
            </div>
          </div>

          <div className="flex flex-wrap gap-4 mb-6">
            <div className="flex-1 min-w-[200px]">
              <input
                type="text"
                placeholder="Buscar cliente..."
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="relative">
              <select 
                className="appearance-none bg-white border border-gray-300 rounded-md px-4 py-2 pr-8 w-48"
                value={filtroTiempo}
                onChange={(e) => setFiltroTiempo(e.target.value)}
              >
                <option value="7">Últimos 7 días</option>
                <option value="15">Últimos 15 días</option>
                <option value="30">Últimos 30 días</option>
              </select>
              <FaChevronDown className="absolute right-3 top-3 text-gray-400" />
            </div>
            <div className="relative">
              <select 
                className="appearance-none bg-white border border-gray-300 rounded-md px-4 py-2 pr-8 w-48"
                value={estadoFiltro}
                onChange={(e) => setEstadoFiltro(e.target.value)}
              >
                <option value="todos">Todos los estados</option>
                <option value={ESTADOS.PENDIENTE}>Pendientes</option>
                <option value={ESTADOS.NOTIFICADO}>Notificados</option>
                <option value={ESTADOS.EN_NEGOCIACION}>En negociación</option>
                <option value={ESTADOS.PLAN_PAGO}>Plan de pago</option>
                <option value={ESTADOS.RECLAMACION_LEGAL}>Reclamación legal</option>
                <option value={ESTADOS.INCOBRABLE}>Incobrables</option>
                <option value={ESTADOS.PAGADO}>Pagados</option>
              </select>
              <FaChevronDown className="absolute right-3 top-3 text-gray-400" />
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columnas.map(col => (
                    <th key={col.key} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {col.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {clientesAtrasadosState.map((cliente) => (
                  <tr key={cliente.id}>
                    {columnas.map(col => (
                      <td key={`${cliente.id}-${col.key}`} className="px-6 py-4 whitespace-nowrap">
                        {col.render ? col.render(cliente[col.dataIndex], cliente) : cliente[col.dataIndex]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      
      {selectedClient && (
        <DetallesCuotasModal
          cliente={selectedClient}
          onClose={() => setSelectedClient(null)}
        />
      )}
    </div>
  );
};

export default Informes;
