import React, { useEffect, useState } from 'react';
import { supabase } from '../../utils/supabaseClient';
import Table from '../../components/Table';
import Select from 'react-select';
import { components } from 'react-select';

const Pagos = () => {
  const [pagos, setPagos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [cuantia, setCuantia] = useState('');
  const [selectedModalidadPago, setSelectedModalidadPago] = useState({ value: 'Tarjeta de crédito', label: 'Tarjeta de crédito' });
  const [fecha, setFecha] = useState(new Date().toISOString().split('T')[0]);
  const [showFormPagoInicial, setShowFormPagoInicial] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [leads, setLeads] = useState([]);
  const [selectedCarencia, setSelectedCarencia] = useState(null);
  const [showFormPagoExistente, setShowFormPagoExistente] = useState(false);
  const [showFormPagoSinCuotas, setShowFormPagoSinCuotas] = useState(false);
  
  const fetchDatos = async () => {
    try {
      setLoading(true);
      // Fetch pagos data
      const { data: pagosData, error: pagosError } = await supabase
        .from('pagos')
        .select('cliente_id, cuantia, modalidad_de_pago, fecha');

      if (pagosError) {
        throw pagosError;
      }

      // Fetch all cliente data
      const { data: clientesData, error: clientesError } = await supabase
        .from('clientes')
        .select('id, nombre, apellidos');

      if (clientesError) {
        throw clientesError;
      }

      // Fetch leads data
      const { data: leadsData, error: leadsError } = await supabase
        .from('leads')
        .select('id, nombre, apellidos');

      if (leadsError) {
        throw leadsError;
      }

      // Combine the data
      const combinedData = pagosData.map(pago => {
        const cliente = clientesData.find(c => c.id === pago.cliente_id);
        return {
          ...pago,
          nombre: cliente ? cliente.nombre : '',
          apellidos: cliente ? cliente.apellidos : '',
        };
      });

      setPagos(combinedData);
      setClientes(clientesData);
      setLeads(leadsData);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDatos();
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: 'Nombre',
      accessor: 'nombre',
    },
    {
      Header: 'Apellidos',
      accessor: 'apellidos',
    },
    {
      Header: 'Cuantía',
      accessor: 'cuantia',
    },
    {
      Header: 'Modalidad de Pago',
      accessor: 'modalidad_de_pago',
    },
    {
      Header: 'Fecha',
      accessor: 'fecha',
    },
  ], []);

  const handleAddPago = async () => {
    if (!selectedCliente || !cuantia || !selectedModalidadPago || !fecha) {
      alert('Por favor, complete todos los campos');
      return;
    }

    const { data: pagoData, error: pagoError } = await supabase
      .from('pagos')
      .insert([
        { cliente_id: selectedCliente.value, cuantia, modalidad_de_pago: selectedModalidadPago.value, fecha }
      ]);

    if (pagoError) {
      console.error('Error adding pago:', pagoError.message);
      return;
    }

    // Check and update cuotas
    const { data: cuotasData, error: cuotasError } = await supabase
      .from('cuotas')
      .select('id, cuantia, fecha_prevista, estado')
      .eq('cliente_id', selectedCliente.value)
      .order('fecha_prevista', { ascending: true });

    if (cuotasError) {
      console.error('Error fetching cuotas:', cuotasError.message);
      return;
    }

    let remainingAmount = parseFloat(cuantia);
    for (const cuota of cuotasData) {
      if (remainingAmount >= cuota.cuantia && cuota.estado !== 'Cuota Pagada') {
        remainingAmount -= cuota.cuantia;
        await supabase
          .from('cuotas')
          .update({ estado: 'Cuota Pagada' })
          .eq('id', cuota.id);
      }
    }

    setPagos([...pagos, { cliente_id: selectedCliente.value, cuantia, modalidad_de_pago: selectedModalidadPago.value, fecha, nombre: selectedCliente.label.split(' ')[0], apellidos: selectedCliente.label.split(' ')[1] }]);
    setShowForm(false);
  };

  const handleAddPagoSinCuotas = async () => {
    if (!selectedCliente || !cuantia || !selectedModalidadPago || !fecha) {
      alert('Por favor, complete todos los campos');
      return;
    }

    const { data: pagoData, error: pagoError } = await supabase
      .from('pagos')
      .insert([
        { cliente_id: selectedCliente.value, cuantia, modalidad_de_pago: selectedModalidadPago.value, fecha }
      ]);

    if (pagoError) {
      console.error('Error adding pago:', pagoError.message);
      alert('Error al añadir el pago. Por favor, intente nuevamente.');
      return;
    }

    setPagos([...pagos, { cliente_id: selectedCliente.value, cuantia, modalidad_de_pago: selectedModalidadPago.value, fecha, nombre: selectedCliente.label.split(' ')[0], apellidos: selectedCliente.label.split(' ')[1] }]);
    
    // Mostrar mensaje de éxito
    alert('Pago añadido exitosamente');
    
    // Cerrar el formulario
    setShowFormPagoSinCuotas(false);
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const handleClienteChange = (selectedOption) => {
    setSelectedCliente(selectedOption);
  };

  const handleModalidadPagoChange = (selectedOption) => {
    setSelectedModalidadPago(selectedOption);
  };

  const handleShowFormPagoInicial = () => {
    setShowFormPagoInicial(!showFormPagoInicial);
  };

  const handleLeadChange = (selectedOption) => {
    setSelectedLead(selectedOption);
  };

  const handlePlanChange = (selectedOption) => {
    setSelectedPlan(selectedOption);
  };

  const handleProcesarPagoInicial = async () => {
    if (!selectedLead || !selectedPlan || !selectedCarencia) {
      alert('Por favor, complete todos los campos');
      return;
    }

    try {
      // 1. Obtener datos del lead
      const { data: leadData, error: leadError } = await supabase
        .from('leads')
        .select('*')
        .eq('id', selectedLead.value)
        .single();

      if (leadError) throw leadError;

      // 2. Crear cliente con los datos del lead
      const clienteData = {
        id: selectedLead.value,
        nombre: leadData.nombre,
        apellidos: leadData.apellidos,
        telefono: leadData.telefono,
        email: leadData.email,
        provincia: leadData.provincia,
        domicilio: leadData.domicilio,
        created_at: new Date(),
        // Campos opcionales que pueden venir del lead
        estado_civil: leadData.estado_civil || null,
        bancoHipoteca: leadData.banco || null,
        precio_vivienda: leadData.precio_vivienda || null,
        ingresosBrutosAnuales: leadData.ingresos_brutos || null,
        ingresosNetosAnuales: leadData.ingresos_netos || null,
        pagoMensualHipoteca: leadData.cuota_mensual || null,
        vivienda_habitual: leadData.vivienda_habitual || null,
        fecha_firma_hipoteca: leadData.fecha_firma || null,
        Carencia: selectedCarencia.label // Añadimos la carencia seleccionada
      };

      const { error: clienteError } = await supabase
        .from('clientes')
        .upsert([clienteData], { onConflict: 'id' });

      if (clienteError) throw clienteError;

      // 3. Insertar en plan_honorarios
      const { error: planError } = await supabase
        .from('plan_honorarios')
        .insert([{
          user_id: selectedLead.value,
          duracion_carencia: selectedCarencia.label,
          total_honorarios: selectedPlan.honorarios,
          tipo_plan: selectedPlan.label,
          pago_inicial: selectedPlan.pagoInicial,
          pago_mensual: selectedPlan.pagoMensual,
          nr_de_cuotas: selectedPlan.cuotas,
          created_at: new Date()
        }]);

      if (planError) throw planError;

      // 4. Insertar/Actualizar en customer_journey
      const { error: journeyError } = await supabase
        .from('customer_journey')
        .upsert([{
          user_id: selectedLead.value,
          estado: 'Cliente Primeros Pasos',
          timestamp: new Date(),
          updated_at: new Date()
        }], { onConflict: 'user_id' });

      if (journeyError) throw journeyError;

      // 5. Insertar en pagos
      const { error: pagoError } = await supabase
        .from('pagos')
        .insert([{
          cliente_id: selectedLead.value,
          cuantia: selectedPlan.pagoInicial,
          modalidad_de_pago: selectedModalidadPago.value,
          fecha: new Date().toISOString().split('T')[0]
        }]);

      if (pagoError) throw pagoError;

      // 6. Insertar cuotas si existen
      if (selectedPlan.cuotas > 0) {
        const cuotas = [];
        const currentDate = new Date();
        
        for (let i = 0; i < selectedPlan.cuotas; i++) {
          const fechaPrevista = new Date(currentDate);
          fechaPrevista.setMonth(currentDate.getMonth() + i + 1);
          
          cuotas.push({
            cliente_id: selectedLead.value,
            cuantia: selectedPlan.pagoMensual,
            fecha_prevista: fechaPrevista.toISOString().split('T')[0],
            estado: 'pendiente'
          });
        }

        const { error: cuotasError } = await supabase
          .from('cuotas')
          .insert(cuotas);

        if (cuotasError) throw cuotasError;
      }

      // Si todo sale bien, mostrar mensaje de éxito y resetear el formulario
      alert('Cliente creado y pago inicial procesado correctamente');
      setShowFormPagoInicial(false);
      setSelectedLead(null);
      setSelectedCarencia(null);
      setSelectedPlan(null);
      
      // Actualizar la tabla de pagos
      fetchDatos();

    } catch (error) {
      console.error('Error al procesar el pago inicial:', error);
      alert('Error al procesar el pago inicial. Por favor, intente nuevamente.');
    }
  };

  const carenciaOptions = [
    { value: 'carencia2o5', label: 'Carencia 2 o 5 años' },
    { value: 'carencia1', label: 'Carencia 1 año' }
  ];

  const getPlanOptions = (carencia) => {
    if (carencia === 'carencia2o5') {
      return [
        { 
          value: 'pro', 
          label: 'Pro',
          honorarios: 1489,
          pagoInicial: 499,
          pagoMensual: 99,
          cuotas: 10
        },
        { 
          value: 'premium', 
          label: 'Premium',
          honorarios: 999,
          pagoInicial: 999,
          pagoMensual: 0,
          cuotas: 0
        },
        { 
          value: 'basic', 
          label: 'Basic',
          honorarios: 1485,
          pagoInicial: 99,
          pagoMensual: 99,
          cuotas: 14
        }
      ];
    } else if (carencia === 'carencia1') {
      return [
        { 
          value: 'pro', 
          label: 'Pro',
          honorarios: 793,
          pagoInicial: 199,
          pagoMensual: 99,
          cuotas: 6
        },
        { 
          value: 'premium', 
          label: 'Premium',
          honorarios: 499,
          pagoInicial: 499,
          pagoMensual: 0,
          cuotas: 0
        },
        { 
          value: 'basic', 
          label: 'Basic',
          honorarios: 1485,
          pagoInicial: 99,
          pagoMensual: 99,
          cuotas: 14
        }
      ];
    }
    return [];
  };

  const handleCarenciaChange = (selectedOption) => {
    setSelectedCarencia(selectedOption);
    setSelectedPlan(null); // Reset plan when carencia changes
  };

  const handleShowFormPagoSinCuotas = () => {
    setShowFormPagoSinCuotas(!showFormPagoSinCuotas);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const clienteOptions = clientes.map(cliente => ({
    value: cliente.id,
    label: `${cliente.nombre} ${cliente.apellidos}`
  }));

  const modalidadPagoOptions = [
    { value: 'Tarjeta de crédito', label: 'Tarjeta de crédito' },
    { value: 'Transferencia', label: 'Transferencia' },
    { value: 'Efectivo', label: 'Efectivo' },
    { value: 'Descuento', label: 'Descuento' }
  ];

  const leadOptions = leads.map(lead => ({
    value: lead.id,
    label: `${lead.nombre} ${lead.apellidos}`
  }));

  return (
    <div className="container mx-auto px-4 py-6 max-w-7xl">
      {/* Header con botones de acción */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 mb-6">
        <div className="p-6 sm:p-8 bg-gradient-to-r from-blue-50 via-indigo-50 to-blue-50">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <h1 className="text-2xl font-bold text-gray-900">Gestión de Pagos</h1>
            <div className="flex flex-wrap gap-3">
              <button 
                onClick={handleShowForm}
                className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-white text-sm font-medium rounded-lg transition-all duration-200 shadow-sm hover:shadow-md"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Pago Regular
              </button>
              <button 
                onClick={handleShowFormPagoInicial}
                className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700 text-white text-sm font-medium rounded-lg transition-all duration-200 shadow-sm hover:shadow-md"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
                Pago Inicial
              </button>
              <button 
                onClick={handleShowFormPagoSinCuotas}
                className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 text-white text-sm font-medium rounded-lg transition-all duration-200 shadow-sm hover:shadow-md"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2z" />
                </svg>
                Pago Sin Cuotas
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal para Pago Regular */}
      {showForm && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex items-center justify-between pb-4 mb-4 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-900">Añadir Pago Regular</h3>
                    <button
                      onClick={() => setShowForm(false)}
                      className="text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Cliente
                      </label>
                      <Select
                        options={clienteOptions}
                        onChange={handleClienteChange}
                        className="w-full"
                        classNamePrefix="select"
                        placeholder="Seleccionar cliente..."
                        isClearable
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Cuantía
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input
                          type="number"
                          value={cuantia}
                          onChange={(e) => setCuantia(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Modalidad de Pago
                      </label>
                      <Select
                        options={modalidadPagoOptions}
                        onChange={handleModalidadPagoChange}
                        value={selectedModalidadPago}
                        className="w-full"
                        classNamePrefix="select"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Fecha
                      </label>
                      <input
                        type="date"
                        value={fecha}
                        onChange={(e) => setFecha(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={handleAddPago}
                    className="inline-flex w-full justify-center rounded-md bg-gradient-to-r from-blue-600 to-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:from-blue-700 hover:to-indigo-700 sm:ml-3 sm:w-auto"
                  >
                    Guardar Pago
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowForm(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal para Pago Inicial */}
      {showFormPagoInicial && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex items-center justify-between pb-4 mb-4 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-900">Pago Inicial</h3>
                    <button
                      onClick={() => setShowFormPagoInicial(false)}
                      className="text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Lead
                      </label>
                      <Select
                        options={leadOptions}
                        onChange={handleLeadChange}
                        value={selectedLead}
                        className="w-full"
                        classNamePrefix="select"
                        placeholder="Seleccionar lead..."
                        isClearable
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Duración de la carencia
                      </label>
                      <Select
                        options={carenciaOptions}
                        onChange={handleCarenciaChange}
                        value={selectedCarencia}
                        className="w-full"
                        classNamePrefix="select"
                        placeholder="Seleccionar duración..."
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Plan de pago
                      </label>
                      <Select
                        options={selectedCarencia ? getPlanOptions(selectedCarencia.value) : []}
                        onChange={handlePlanChange}
                        value={selectedPlan}
                        isDisabled={!selectedCarencia}
                        className="w-full"
                        classNamePrefix="select"
                        placeholder="Seleccionar plan..."
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999
                          }),
                          menu: (base) => ({
                            ...base,
                            maxHeight: '400px',
                            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                            backgroundColor: 'white',
                            borderRadius: '0.5rem'
                          }),
                          option: (base, state) => ({
                            ...base,
                            padding: '16px',
                            backgroundColor: state.isFocused ? '#f3f4f6' : 'white',
                            borderBottom: '1px solid #f0f0f0',
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: '#f3f4f6'
                            }
                          }),
                          control: (base) => ({
                            ...base,
                            minHeight: 50,
                            padding: '4px 8px'
                          })
                        }}
                        components={{
                          Option: ({ children, ...props }) => (
                            <components.Option {...props}>
                              <div className="py-2">
                                <div className="text-base font-medium text-gray-900">
                                  {props.data.label}
                                </div>
                                {props.data.honorarios && (
                                  <div className="mt-1 space-y-1">
                                    <div className="text-sm text-gray-600">
                                      Honorarios: <span className="font-medium text-gray-900">{props.data.honorarios}€</span>
                                    </div>
                                    <div className="text-sm text-gray-600">
                                      Pago inicial: <span className="font-medium text-gray-900">{props.data.pagoInicial}€</span>
                                    </div>
                                    <div className="text-sm text-gray-600">
                                      Pago mensual: <span className="font-medium text-gray-900">{props.data.pagoMensual}€</span>
                                    </div>
                                    <div className="text-sm text-gray-600">
                                      Cuotas: <span className="font-medium text-gray-900">{props.data.cuotas}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </components.Option>
                          )
                        }}
                      />
                    </div>

                    {selectedPlan && (
                      <div className="mt-4 bg-indigo-50 rounded-lg p-4">
                        <h4 className="text-sm font-medium text-indigo-800 mb-2">Resumen del plan</h4>
                        <div className="grid grid-cols-2 gap-3 text-sm">
                          <div>
                            <span className="text-gray-600">Total Honorarios:</span>
                            <span className="ml-1 font-medium text-gray-900">{selectedPlan.honorarios}€</span>
                          </div>
                          <div>
                            <span className="text-gray-600">Pago Inicial:</span>
                            <span className="ml-1 font-medium text-gray-900">{selectedPlan.pagoInicial}€</span>
                          </div>
                          <div>
                            <span className="text-gray-600">Pago Mensual:</span>
                            <span className="ml-1 font-medium text-gray-900">{selectedPlan.pagoMensual}€</span>
                          </div>
                          <div>
                            <span className="text-gray-600">Número de cuotas:</span>
                            <span className="ml-1 font-medium text-gray-900">{selectedPlan.cuotas}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={handleProcesarPagoInicial}
                    disabled={!selectedLead || !selectedCarencia || !selectedPlan}
                    className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto ${
                      (!selectedLead || !selectedCarencia || !selectedPlan)
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700'
                    }`}
                  >
                    Procesar Pago Inicial
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowFormPagoInicial(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal para Pago Sin Cuotas */}
      {showFormPagoSinCuotas && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex items-center justify-between pb-4 mb-4 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-900">Pago Sin Cuotas</h3>
                    <button
                      onClick={() => setShowFormPagoSinCuotas(false)}
                      className="text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Cliente
                      </label>
                      <Select
                        options={clienteOptions}
                        onChange={handleClienteChange}
                        value={selectedCliente}
                        className="w-full"
                        classNamePrefix="select"
                        placeholder="Seleccionar cliente..."
                        isClearable
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Cuantía
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input
                          type="number"
                          value={cuantia}
                          onChange={(e) => setCuantia(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Modalidad de Pago
                      </label>
                      <Select
                        options={modalidadPagoOptions}
                        onChange={handleModalidadPagoChange}
                        value={selectedModalidadPago}
                        className="w-full"
                        classNamePrefix="select"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Fecha
                      </label>
                      <input
                        type="date"
                        value={fecha}
                        onChange={(e) => setFecha(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={handleAddPagoSinCuotas}
                    className="inline-flex w-full justify-center rounded-md bg-gradient-to-r from-purple-600 to-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:from-purple-700 hover:to-pink-700 sm:ml-3 sm:w-auto"
                  >
                    Guardar Pago
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowFormPagoSinCuotas(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Tabla de pagos */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="p-6">
          <Table columns={columns} data={pagos} />
        </div>
      </div>
    </div>
  );
}

export default Pagos;
