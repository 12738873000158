import React, { useMemo, useState } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [focused, setFocused] = useState(false);

  return (
    <div className={`flex items-center h-12 border border-gray-300 rounded-lg overflow-hidden transition-all duration-200 ${focused ? 'ring-2 ring-purple-500 border-transparent' : 'hover:shadow-md'}`}>
      <div className="flex items-center px-3 text-gray-400">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
      </div>
      <input
        value={globalFilter || ''}
        onChange={e => setGlobalFilter(e.target.value || undefined)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder={`Buscar en ${count} registros...`}
        className="p-3 border-none outline-none w-full text-gray-600 placeholder-gray-400"
      />
      {globalFilter && (
        <button 
          onClick={() => setGlobalFilter('')}
          className="p-3 text-gray-400 hover:text-gray-600 transition-colors duration-200"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      )}
    </div>
  );
}

const Table = ({ columns, data, isLeadsPage, isLoading }) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const defaultColumn = useMemo(() => ({ Filter: () => null }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter: setTableGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { globalFilter },
    },
    useGlobalFilter,
    useSortBy
  );

  const pageCount = Math.ceil(rows.length / rowsPerPage);
  const paginatedRows = rows.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

  const TableSkeleton = () => (
    <div className="animate-pulse">
      <div className="h-12 bg-gray-200 rounded-lg mb-4"></div>
      {[...Array(5)].map((_, index) => (
        <div key={index} className="h-16 bg-gray-100 rounded-lg mb-2"></div>
      ))}
    </div>
  );

  return (
    <section className="py-4 overflow-hidden">
      <div className="container mx-auto px-4 max-w-full">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4 mb-6">
          <div className="w-full lg:w-96">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setTableGlobalFilter}
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Mostrar</span>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setCurrentPage(0);
              }}
              className="p-2 border border-gray-300 rounded-lg bg-white hover:border-gray-400 transition-colors duration-200"
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <span className="text-sm text-gray-600">por página</span>
          </div>
        </div>

        <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
          {isLoading ? (
            <div className="p-6">
              <TableSkeleton />
            </div>
          ) : rows.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
              <div className="bg-gray-50 p-4 rounded-full mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
                </svg>
              </div>
              <p className="text-lg font-medium mb-1">No se encontraron resultados</p>
              <p className="text-sm text-gray-400">Intenta ajustar los filtros de búsqueda</p>
              <button 
                onClick={() => setGlobalFilter('')}
                className="mt-4 px-4 py-2 bg-purple-50 text-purple-600 rounded-lg hover:bg-purple-100 transition-colors duration-200"
              >
                Limpiar filtros
              </button>
            </div>
          ) : (
            <div className="px-1">
              <div className="w-full overflow-x-auto">
                <table {...getTableProps()} className="w-full min-w-max">
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} className="text-left">
                        {headerGroup.headers.map(column => {
                          const { key, ...rest } = column.getHeaderProps(column.getSortByToggleProps());
                          return (
                            <th key={key} {...rest} className="p-3 border-b border-gray-300 bg-gray-100 text-gray-700">
                              <div className="flex items-center">
                                {column.render('Header')}
                                <span className="ml-2">
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                      </svg>
                                    ) : (
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                                      </svg>
                                    )
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {paginatedRows.map((row, i) => {
                      prepareRow(row);
                      const backgroundColor = i % 2 === 0 ? 'bg-gray-50' : 'bg-white';
                      return (
                        <tr key={row.id} {...row.getRowProps()} className={`${backgroundColor} hover:bg-gray-100`}>
                          {row.cells.map(cell => {
                            const { key, ...rest } = cell.getCellProps();
                            return (
                              <td key={key} {...rest} className="py-3 pr-4 pl-3 border-b border-gray-200">
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        {rows.length > 0 && (
          <div className="flex flex-col md:flex-row justify-between items-center mt-6 gap-4">
            <p className="text-sm text-gray-600">
              Mostrando <span className="font-medium">{currentPage * rowsPerPage + 1}</span> a <span className="font-medium">{Math.min((currentPage + 1) * rowsPerPage, rows.length)}</span> de <span className="font-medium">{rows.length}</span> resultados
            </p>
            
            <div className="flex items-center gap-2">
              <button 
                className="p-2 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 hover:border-gray-400" 
                onClick={() => setCurrentPage(0)} 
                disabled={currentPage === 0}
                title="Primera página"
              >
                {"<<"}
              </button>
              <button 
                className="p-2 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 hover:border-gray-400" 
                onClick={() => setCurrentPage(currentPage - 1)} 
                disabled={currentPage === 0}
                title="Página anterior"
              >
                {"<"}
              </button>
              {Array.from({ length: pageCount }, (_, index) => (
                <button
                  key={index}
                  className={`p-2 ${index === currentPage ? 'bg-gray-300' : 'bg-white'} text-gray-700 rounded-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 hover:border-gray-400`}
                  onClick={() => setCurrentPage(index)}
                  title={`Página ${index + 1}`}
                >
                  {index + 1}
                </button>
              ))}
              <button 
                className="p-2 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 hover:border-gray-400" 
                onClick={() => setCurrentPage(currentPage + 1)} 
                disabled={currentPage >= pageCount - 1}
                title="Página siguiente"
              >
                {">"}
              </button>
              <button 
                className="p-2 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 hover:border-gray-400" 
                onClick={() => setCurrentPage(pageCount - 1)} 
                disabled={currentPage >= pageCount - 1}
                title="Última página"
              >
                {">>"}
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Table;
