import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Table from '../../components/Table';
import { supabase } from '../../utils/supabaseClient';

const Leads = () => {
  const [data, setData] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLead, setNewLead] = useState({
    nombre: '',
    apellidos: '',
    telefono: '',
    email: ''
  });
  const [csvFile, setCsvFile] = useState(null);
  const [importProgress, setImportProgress] = useState({
    isOpen: false,
    total: 0,
    current: 0,
    success: [],
    errors: []
  });
  const [activeTab, setActiveTab] = useState('Todos');

  const fetchLeads = async () => {
    // Fetch data from leads
    const { data: resultados, error: errorResultados } = await supabase
      .from('leads')
      .select('id, nombre, apellidos, telefono, email, fase_comercial, usuario_asignado, tipocbp');

    if (errorResultados) {
      console.error('Error fetching leads:', errorResultados);
      return;
    }

    // Fetch data from customer_journey using the IDs from leads
    const ids = resultados.map(resultado => resultado.id);
    const { data: journey, error: errorJourney } = await supabase
      .from('customer_journey')
      .select('user_id, estado')
      .in('user_id', ids);

    if (errorJourney) {
      console.error('Error fetching customer_journey:', errorJourney);
      return;
    }

    // Combine data
    const combinedData = resultados.map(resultado => {
      const journeyData = journey.find(j => j.user_id === resultado.id);
      return {
        ...resultado,
        estado: journeyData ? journeyData.estado : 'N/A',
      };
    });

    setData(combinedData);
  };

  useEffect(() => {
    const fetchUsuarios = async () => {
      const { data: usuarios, error } = await supabase
        .from('usuarios')
        .select('user_id, nombre, apellidos');

      if (error) {
        console.error('Error fetching usuarios:', error);
        return;
      }

      setUsuarios(usuarios.map(usuario => ({
        value: usuario.user_id,
        label: `${usuario.nombre} ${usuario.apellidos}`,
      })));
    };

    fetchLeads();
    fetchUsuarios();
  }, []);

  const handleUsuarioChange = async (leadId, userId) => {
    const { error } = await supabase
      .from('leads')
      .update({ usuario_asignado: userId })
      .eq('id', leadId);

    if (error) {
      console.error('Error updating usuario_asignado:', error);
    } else {
      setData((prevData) =>
        prevData.map((lead) =>
          lead.id === leadId ? { ...lead, usuario_asignado: userId } : lead
        )
      );
    }
  };

  const handleFaseComercialChange = async (leadId, newFase) => {
    const { error } = await supabase
      .from('leads')
      .update({ fase_comercial: newFase })
      .eq('id', leadId);

    if (error) {
      console.error('Error actualizando fase comercial:', error);
    } else {
      setData((prevData) =>
        prevData.map((lead) =>
          lead.id === leadId ? { ...lead, fase_comercial: newFase } : lead
        )
      );
    }
  };

  const handleTipoCBPChange = async (leadId, newTipo) => {
    const { error } = await supabase
      .from('leads')
      .update({ tipocbp: newTipo })
      .eq('id', leadId);

    if (error) {
      console.error('Error actualizando tipo CBP:', error);
    } else {
      setData((prevData) =>
        prevData.map((lead) =>
          lead.id === leadId ? { ...lead, tipocbp: newTipo } : lead
        )
      );
    }
  };

  const handleCreateLead = async () => {
    // Validación básica
    if (!newLead.nombre || !newLead.apellidos || !newLead.telefono || !newLead.email) {
      alert('Por favor, completa los campos obligatorios (nombre, apellidos, teléfono y email)');
      return;
    }
  
    try {
      // Primero creamos el usuario en auth.users
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email: newLead.email,
        password: generateRandomPassword(),
        options: {
          data: {
            nombre: newLead.nombre,
            apellidos: newLead.apellidos
          }
        }
      });
  
      if (authError) {
        console.error('Error creating auth user:', authError);
        alert('Error al crear el usuario');
        return;
      }
  
      // Insertamos el nuevo lead usando el ID del usuario creado
      const { data: insertedLead, error } = await supabase
        .from('leads')
        .insert([{
          id: authData.user.id,
          nombre: newLead.nombre,
          apellidos: newLead.apellidos,
          telefono: newLead.telefono,
          email: newLead.email,
          fase_comercial: 'Nuevo',
          created_at: new Date().toISOString()
        }])
        .select();
  
      if (error) {
        console.error('Error creating lead:', error);
        alert('Error al crear el lead');
        return;
      }
  
      // Crear entrada en user_progress
      const { error: progressError } = await supabase
        .from('user_progress')
        .insert([{
          user_id: authData.user.id,
          nombre: newLead.nombre,
          apellidos: newLead.apellidos,
          telefono: newLead.telefono,
          estado: 'Nuevo',
          politicaPrivacidad: true, // o false según tu lógica
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          formData: {}, // O cualquier dato inicial que necesites
          current_question: 0 // O el valor inicial que prefieras
        }]);
  
      if (progressError) {
        console.error('Error creating user progress:', progressError);
        return;
      }
  
      // Ahora sí, crear entrada en customer_journey
      if (insertedLead && insertedLead[0]) {
        const currentTimestamp = new Date().toISOString();
        const { error: journeyError } = await supabase
          .from('customer_journey')
          .insert([{
            user_id: authData.user.id,
            estado: 'Nuevo',
            timestamp: currentTimestamp,
            updated_at: currentTimestamp,
            prevision_primerpago: null
          }]);
  
        if (journeyError) {
          console.error('Error creating customer journey:', journeyError);
        }
      }
  
      // Actualizar la lista de leads
      setData([...data, { ...insertedLead[0], estado: 'Nuevo' }]);
      
      // Cerrar el modal y resetear el formulario
      setIsModalOpen(false);
      setNewLead({
        nombre: '',
        apellidos: '',
        telefono: '',
        email: ''
      });
  
      alert('Lead creado exitosamente');
    } catch (error) {
      console.error('Error:', error);
      alert('Error al crear el lead');
    }
  };
  
  // Función para generar una contraseña aleatoria
  const generateRandomPassword = () => {
    const length = 12;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
  };

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  const columns = [
    {
      Header: 'Nombre',
      accessor: 'nombre',
      Cell: ({ row }) => (
        <Link to={`/leads/${row.original.id}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          {row.original.nombre}
        </Link>
      ),
    },
    {
      Header: 'Apellidos',
      accessor: 'apellidos',
    },
    {
      Header: 'Correo',
      accessor: 'email',
    },
    {
      Header: 'Teléfono',
      accessor: 'telefono',
    },
    {
      Header: 'Estado',
      accessor: 'estado',
    },
    {
      Header: 'Fase Comercial',
      accessor: 'fase_comercial',
      Cell: ({ row }) => {
        const [isOpen, setIsOpen] = useState(false);
        const faseOptions = [
          { value: null, label: '-' },
          { value: 'Nuevo', label: 'Nuevo' },
          { value: 'Interesado', label: 'Interesado' },
          { value: 'Mal Momento', label: 'Mal Momento' },
          { value: 'No contesta', label: 'No contesta' },
          { value: 'Telefono incorrecto', label: 'Telefono incorrecto' },
          { value: 'No tiene perfil', label: 'No tiene perfil' },
          { value: 'No interesado', label: 'No interesado' },
          { value: 'Contratado', label: 'Contratado' }
        ];

        return (
          <div 
            className="relative cursor-pointer"
            onClick={() => setIsOpen(true)}
          >
            {isOpen ? (
              <Select
                options={faseOptions}
                value={faseOptions.find(option => option.value === row.original.fase_comercial)}
                onChange={(selectedOption) => {
                  handleFaseComercialChange(row.original.id, selectedOption.value);
                  setIsOpen(false);
                }}
                className="w-40"
                styles={customStyles}
                menuPortalTarget={document.body}
                autoFocus
                menuIsOpen={true}
                onBlur={() => setIsOpen(false)}
              />
            ) : (
              <div className="px-2 py-1 hover:bg-gray-100 rounded">
                {row.original.fase_comercial || '-'}
              </div>
            )}
          </div>
        );
      }
    },
    {
      Header: 'Usuario Asignado',
      accessor: 'usuario_asignado',
      Cell: ({ row }) => {
        const [isOpen, setIsOpen] = useState(false);
        const usuarioAsignado = row.original.usuario_asignado;

        return (
          <div 
            className="relative cursor-pointer"
            onClick={() => setIsOpen(true)}
          >
            {isOpen ? (
              <Select
                options={usuarios}
                value={usuarios.find(user => user.value === usuarioAsignado)}
                onChange={(selectedOption) => {
                  handleUsuarioChange(row.original.id, selectedOption.value);
                  setIsOpen(false);
                }}
                className="w-56"
                styles={customStyles}
                menuPortalTarget={document.body}
                autoFocus
                menuIsOpen={true}
                onBlur={() => setIsOpen(false)}
              />
            ) : (
              <div className="px-2 py-1 hover:bg-gray-100 rounded">
                {usuarios.find(user => user.value === usuarioAsignado)?.label || 'No asignado'}
              </div>
            )}
          </div>
        );
      }
    },
    {
      Header: 'Tipo CBP',
      accessor: 'tipocbp',
      Cell: ({ row }) => {
        const [isOpen, setIsOpen] = useState(false);
        const tipoOptions = [
          { value: null, label: '-' },
          { value: 'carencia1', label: 'Carencia 1 año' },
          { value: 'carencia2o5', label: 'Carencia 2 o 5 años' }
        ];

        // Función para formatear el texto mostrado
        const formatTipoCBP = (value) => {
          if (!value) return '-';
          switch (value) {
            case 'carencia1':
              return 'Carencia 1 año';
            case 'carencia2o5':
              return 'Carencia 2 o 5 años';
            default:
              return value;
          }
        };

        return (
          <div 
            className="relative cursor-pointer"
            onClick={() => setIsOpen(true)}
          >
            {isOpen ? (
              <Select
                options={tipoOptions}
                value={tipoOptions.find(option => option.value === row.original.tipocbp)}
                onChange={(selectedOption) => {
                  handleTipoCBPChange(row.original.id, selectedOption.value);
                  setIsOpen(false);
                }}
                className="w-48"
                styles={customStyles}
                menuPortalTarget={document.body}
                autoFocus
                menuIsOpen={true}
                onBlur={() => setIsOpen(false)}
              />
            ) : (
              <div className="px-2 py-1 hover:bg-gray-100 rounded">
                {formatTipoCBP(row.original.tipocbp)}
              </div>
            )}
          </div>
        );
      }
    },
  ];

  const procesarCSV = async (file) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const text = event.target.result;
      const rows = text.split('\n');
      const headers = rows[0].split(',').map(header => header.trim());
      
      setImportProgress({
        isOpen: true,
        total: rows.length - 1,
        current: 0,
        success: [],
        errors: []
      });
      
      for (let i = 1; i < rows.length; i++) {
        if (rows[i].trim() === '') continue;
        
        const valores = rows[i].split(',').map(valor => valor.trim());
        const lead = {
          nombre: valores[headers.indexOf('nombre')] || '',
          apellidos: valores[headers.indexOf('apellidos')] || '',
          telefono: valores[headers.indexOf('telefono')] || '',
          email: valores[headers.indexOf('email')] || '',
          fase_comercial: valores[headers.indexOf('fase_comercial')] || 'Nuevo'
        };
        
        try {
          // Validación solo de campos obligatorios
          if (!lead.email || !lead.nombre || !lead.apellidos) {
            throw new Error('Faltan campos obligatorios (nombre, apellidos o email)');
          }

          if (!lead.telefono) {
            throw new Error('El teléfono es obligatorio');
          }

          const { data: authData, error: authError } = await supabase.auth.signUp({
            email: lead.email,
            password: generateRandomPassword(),
            options: {
              data: {
                nombre: lead.nombre,
                apellidos: lead.apellidos
              }
            }
          });

          if (authError) throw authError;

          // Crear el objeto de inserción sin incluir provincia si es null
          const leadData = {
            id: authData.user.id,
            nombre: lead.nombre,
            apellidos: lead.apellidos,
            telefono: lead.telefono,
            email: lead.email,
            fase_comercial: lead.fase_comercial,
            created_at: new Date().toISOString()
          };

          // Solo añadir provincia si existe
          if (lead.fase_comercial) {
            leadData.fase_comercial = lead.fase_comercial;
          }

          const { error: leadError } = await supabase
            .from('leads')
            .insert([leadData]);

          if (leadError) throw leadError;

          // Crear entrada en user_progress
          await supabase
            .from('user_progress')
            .insert([{
              user_id: authData.user.id,
              nombre: lead.nombre,
              apellidos: lead.apellidos,
              telefono: lead.telefono,
              estado: 'Nuevo',
              politicaPrivacidad: true,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              formData: {},
              current_question: 0
            }]);

          // Crear entrada en customer_journey
          await supabase
            .from('customer_journey')
            .insert([{
              user_id: authData.user.id,
              estado: 'Nuevo',
              timestamp: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              prevision_primerpago: null
            }]);

          setImportProgress(prev => ({
            ...prev,
            current: i,
            success: [...prev.success, `${lead.nombre} ${lead.apellidos}`]
          }));

        } catch (error) {
          setImportProgress(prev => ({
            ...prev,
            current: i,
            errors: [...prev.errors, `Error en fila ${i}: ${lead.email} - ${error.message}`]
          }));
        }
      }
    };

    reader.readAsText(file);
  };

  const ImportProgressModal = () => {
    if (!importProgress.isOpen) return null;

    const progress = (importProgress.current / importProgress.total) * 100;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-lg w-full max-w-2xl">
          <h2 className="text-2xl font-bold mb-6">Importando Leads</h2>
          
          {/* Barra de progreso */}
          <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
            <div 
              className="bg-blue-600 h-4 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          
          <p className="mb-4">
            Progreso: {importProgress.current} de {importProgress.total} leads
          </p>

          {/* Éxitos */}
          {importProgress.success.length > 0 && (
            <div className="mb-4">
              <h3 className="font-bold text-green-600 mb-2">
                Importados correctamente ({importProgress.success.length}):
              </h3>
              <div className="max-h-32 overflow-y-auto bg-green-50 p-2 rounded">
                {importProgress.success.map((msg, i) => (
                  <div key={i} className="text-sm text-green-700">{msg}</div>
                ))}
              </div>
            </div>
          )}

          {/* Errores */}
          {importProgress.errors.length > 0 && (
            <div className="mb-4">
              <h3 className="font-bold text-red-600 mb-2">
                Errores ({importProgress.errors.length}):
              </h3>
              <div className="max-h-32 overflow-y-auto bg-red-50 p-2 rounded">
                {importProgress.errors.map((error, i) => (
                  <div key={i} className="text-sm text-red-700">{error}</div>
                ))}
              </div>
            </div>
          )}

          {/* Botón para cerrar */}
          <div className="mt-6 flex justify-end">
            <button
              onClick={() => setImportProgress(prev => ({ ...prev, isOpen: false }))}
              className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-bold">Leads</h1>
        <div className="flex gap-4">
          <label className="flex items-center text-white px-4 py-2 rounded-lg bg-gradient-to-r from-green-500 to-teal-500 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            Importar CSV
            <input
              type="file"
              accept=".csv"
              className="hidden"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  procesarCSV(file);
                }
              }}
            />
          </label>
          
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center text-white px-4 py-2 rounded-lg bg-gradient-to-r from-purple-500 to-blue-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
            Nuevo Lead
          </button>
        </div>
      </div>

      <div className="flex space-x-2 mb-4">
        {['Todos', 'Sin llamadas', 'No contesta', 'Interesados'].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`h-10 px-4 text-sm font-medium transition-colors border-b-2
              ${activeTab === tab 
                ? 'border-purple-500 text-purple-600' 
                : 'border-transparent text-gray-600 hover:border-gray-300'
              }`}
          >
            {tab}
          </button>
        ))}
      </div>

      <Table 
        columns={columns} 
        data={data.filter(lead => {
          if (activeTab === 'Interesados') {
            return lead.fase_comercial === 'Interesado';
          } else if (activeTab === 'No contesta') {
            return lead.fase_comercial === 'No contesta';
          } else if (activeTab === 'Sin llamadas') {
            return !lead.fase_comercial || lead.fase_comercial === 'Nuevo';
          }
          return true; // Para el tab 'Todos'
        })} 
        isLeadsPage={true} 
      />

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg w-full max-w-xl">
            <h2 className="text-2xl font-bold mb-6">Nuevo Lead</h2>
            
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Nombre</label>
                <input
                  type="text"
                  value={newLead.nombre}
                  onChange={(e) => setNewLead({...newLead, nombre: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4 text-base"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Apellidos</label>
                <input
                  type="text"
                  value={newLead.apellidos}
                  onChange={(e) => setNewLead({...newLead, apellidos: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4 text-base"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Teléfono</label>
                <input
                  type="tel"
                  value={newLead.telefono}
                  onChange={(e) => setNewLead({...newLead, telefono: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4 text-base"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                <input
                  type="email"
                  value={newLead.email}
                  onChange={(e) => setNewLead({...newLead, email: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4 text-base"
                />
              </div>
            </div>

            <div className="mt-8 flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="flex items-center text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200"
              >
                Cancelar
              </button>
              <button
                onClick={handleCreateLead}
                className="flex items-center text-white px-4 py-2 rounded-lg bg-gradient-to-r from-purple-500 to-blue-500"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}

      <ImportProgressModal />
    </div>
  );
};

export default Leads;