// App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Usuarios from './pages/Usuarios/Usuarios';
import UsuarioDetalle from './pages/Usuarios/UsuarioDetalle';
import Leads from './pages/Leads/Leads';
import LeadDetalle from './pages/Leads/LeadDetalle';
import Clientes from './pages/Clientes/Clientes';
import ClienteDetalle from './pages/Clientes/ClienteDetalle';
import Documentacion from './pages/Documentacion/Documentacion'; // Nuevo import para la documentación
import Colaboradores from './pages/Colaboradores/Colaboradores';
import GestoresBancos from './pages/GestoresBancos/GestoresBancos';
import Pagos from './pages/Pagos/Pagos';
import Informes from './pages/Informes/Informes';
import Impagados from './pages/Informes/Impagados';
import Prevision from './pages/Informes/Prevision';
import TusTareas from './pages/Tareas/TusTareas'; // Importa el nuevo componente
import CentralizadorClientes from './pages/Finanzas/CentralizadorClientes'; // Nuevo import para Centralizador Clientes
import Cuotas from './pages/Finanzas/Cuotas'; // Nuevo import para Cuotas
import SignIn from './components/SignIn';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import CentralizadorLlamadas from './pages/Llamadas/CentralizadorLlamadas';
import CBP25 from './pages/Leads/CBP25';
import CBP1 from './pages/Leads/CBP1';

const PrivateRoute = ({ children }) => {
  const { user } = React.useContext(AuthContext);
  return user ? children : <Navigate to="/signin" />;
};

const AppContent = () => {
  const location = useLocation();

  return (
    <div className="flex">
      {location.pathname !== '/signin' && <Navbar />}
      <div className="flex-1 p-4">
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/" element={<Navigate to="/signin" />} /> {/* Redirigir la raíz a /signin */}
          <Route path="/usuarios" element={<PrivateRoute><Usuarios /></PrivateRoute>} />
          <Route path="/usuarios/:id" element={<PrivateRoute><UsuarioDetalle /></PrivateRoute>} />
          <Route path="/leads" element={<PrivateRoute><Leads /></PrivateRoute>} />
          <Route path="/leads/:id" element={<PrivateRoute><LeadDetalle /></PrivateRoute>} />
          <Route path="/clientes" element={<PrivateRoute><Clientes /></PrivateRoute>} />
          <Route path="/clientes/:id" element={<PrivateRoute><ClienteDetalle /></PrivateRoute>} /> 
          <Route path="/clientes/documentacion" element={<PrivateRoute><Documentacion /></PrivateRoute>} /> {/* Nueva ruta */}
          <Route path="/colaboradores" element={<PrivateRoute><Colaboradores /></PrivateRoute>} />
          <Route path="/gestoresbancos" element={<PrivateRoute><GestoresBancos /></PrivateRoute>} />
          <Route path="/finanzas/pagos" element={<PrivateRoute><Pagos /></PrivateRoute>} />
          <Route path="/finanzas/centralizador-clientes" element={<PrivateRoute><CentralizadorClientes /></PrivateRoute>} /> {/* Nueva ruta */}
          <Route path="/finanzas/cuotas" element={<PrivateRoute><Cuotas /></PrivateRoute>} /> {/* Nueva ruta */}
          <Route path="/informes" element={<PrivateRoute><Informes /></PrivateRoute>} />
          <Route path="/informes/impagados" element={<PrivateRoute><Impagados /></PrivateRoute>} />
          <Route path="/informes/prevision" element={<PrivateRoute><Prevision /></PrivateRoute>} />
          <Route path="/tareas" element={<PrivateRoute><TusTareas /></PrivateRoute>} /> 
          <Route path="/llamadas" element={<PrivateRoute><CentralizadorLlamadas /></PrivateRoute>} />
          <Route path="/cbp-1" element={<CBP1 />} />
          <Route path="/cbp-2-5" element={<CBP25 />} />
        </Routes>
      </div>
    </div>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
