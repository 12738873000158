import React, { useState, useEffect } from 'react';
import { supabase } from '../../utils/supabaseClient';

const Prevision = () => {
  const [datosMesActual, setDatosMesActual] = useState({
    previsionPagos: 0,
    cuotasImpagadas: 0,
    numeroClientes: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [datosMeses, setDatosMeses] = useState([]);
  const [totalPagosMes, setTotalPagosMes] = useState(0);
  const [pagosMesActual, setPagosMesActual] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedSections, setExpandedSections] = useState({
    pagos: true,
    prevision: true,
    historico: true
  });
  const [pagosHistoricos, setPagosHistoricos] = useState([]);

  const agruparPagosPorMes = (pagos) => {
    const pagosAgrupados = pagos.reduce((grupos, pago) => {
      const fecha = new Date(pago.fecha);
      const mesKey = fecha.toISOString().substring(0, 7); // Formato: 'YYYY-MM'
      const nombreMes = fecha.toLocaleString('es-ES', { month: 'long', year: 'numeric' });
      
      if (!grupos[mesKey]) {
        grupos[mesKey] = {
          nombreMes,
          pagos: [],
          totalMes: 0,
          fecha: fecha // Guardamos la fecha para ordenar después
        };
      }
      
      grupos[mesKey].pagos.push(pago);
      grupos[mesKey].totalMes += parseFloat(pago.cuantia);
      return grupos;
    }, {});

    // Convertir a array y ordenar por fecha (más antiguo primero)
    return Object.entries(pagosAgrupados)
      .map(([key, value]) => ({
        ...value,
        mesKey: key
      }))
      .sort((a, b) => a.fecha - b.fecha);
  };

  const fetchPrevisionData = async () => {
    try {
      // Obtener datos de cuotas
      const { data: cuotasData, error: cuotasError } = await supabase
        .from('cuotas')
        .select('cuantia, estado, cliente_id, fecha_prevista');

      if (cuotasError) throw cuotasError;
      console.log('Datos de cuotas:', cuotasData);

      const fechaActual = new Date();
      const mesActual = fechaActual.getMonth();
      const añoActual = fechaActual.getFullYear();

      // Filtrar cuotas impagadas hasta hoy
      const cuotasImpagadas = cuotasData.filter(cuota => cuota.estado === 'pendiente' && new Date(cuota.fecha_prevista) <= fechaActual);
      const totalCuotasImpagadas = cuotasImpagadas.reduce((sum, cuota) => sum + cuota.cuantia, 0);

      // Filtrar cuotas del mes actual
      const cuotasMesActual = cuotasData.filter(cuota => {
        const fechaCuota = new Date(cuota.fecha_prevista);
        return cuota.estado === 'pendiente' && fechaCuota.getMonth() === mesActual && fechaCuota.getFullYear() === añoActual;
      });
      const previsionPagosMesActual = cuotasMesActual.reduce((sum, cuota) => sum + cuota.cuantia, 0);

      // Obtener IDs únicos de clientes
      const clienteIds = [...new Set(cuotasData.map(cuota => cuota.cliente_id))];

      // Obtener nombres y apellidos de los clientes
      const { data: clientesData, error: clientesError } = await supabase
        .from('clientes')
        .select('id, nombre, apellidos')
        .in('id', clienteIds);

      if (clientesError) throw clientesError;
      console.log('Datos de clientes:', clientesData);

      // Obtener datos de pagos
      const { data: pagosData, error: pagosError } = await supabase
        .from('pagos')
        .select('cliente_id, cuantia, fecha');

      if (pagosError) throw pagosError;

      // Combinar datos de pagos con nombres de clientes
      const pagosMes = pagosData
        .filter(pago => {
          const fechaPago = new Date(pago.fecha);
          return fechaPago.getMonth() === mesActual && fechaPago.getFullYear() === añoActual;
        })
        .map(pago => {
          const cliente = clientesData.find(cliente => cliente.id === pago.cliente_id);
          return {
            ...pago,
            nombre: cliente ? cliente.nombre : 'Desconocido',
            apellidos: cliente ? cliente.apellidos : ''
          };
        });

      const totalMes = pagosMes.reduce((sum, pago) => sum + parseFloat(pago.cuantia), 0);

      setTotalPagosMes(totalMes);
      setPagosMesActual(pagosMes);

      // Actualizar el estado con los datos calculados
      setDatosMesActual({
        previsionPagos: previsionPagosMesActual,
        cuotasImpagadas: totalCuotasImpagadas,
        numeroClientes: clientesData.length
      });

      // Generar previsión para los próximos 12 meses
      const meses = generarProximosMeses(cuotasData);
      setDatosMeses(meses);

      // Obtener pagos históricos de los últimos 12 meses
      const fechaInicio = new Date();
      fechaInicio.setMonth(fechaInicio.getMonth() - 11);
      
      const { data: pagosHistoricosData, error: pagosHistoricosError } = await supabase
        .from('pagos')
        .select('cliente_id, cuantia, fecha')
        .gte('fecha', fechaInicio.toISOString())
        .order('fecha', { ascending: false });

      if (pagosHistoricosError) throw pagosHistoricosError;

      // Combinar con datos de clientes
      const pagosConClientes = pagosHistoricosData.map(pago => {
        const cliente = clientesData.find(cliente => cliente.id === pago.cliente_id);
        return {
          ...pago,
          nombre: cliente ? `${cliente.nombre} ${cliente.apellidos}` : 'Desconocido'
        };
      });

      setPagosHistoricos(agruparPagosPorMes(pagosConClientes));
    } catch (error) {
      console.error('Error fetching prevision data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const generarProximosMeses = (cuotasData) => {
    const meses = [];
    const nombresMeses = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    
    const fechaActual = new Date();
    let año = fechaActual.getFullYear();
    let mes = fechaActual.getMonth();

    for (let i = 0; i < 12; i++) {
      if (mes > 11) {
        mes = 0;
        año++;
      }
      
      // Filtrar cuotas pendientes para el mes específico
      const cuotasMes = cuotasData.filter(cuota => {
        const fechaCuota = new Date(cuota.fecha_prevista);
        return cuota.estado === 'pendiente' && fechaCuota.getMonth() === mes && fechaCuota.getFullYear() === año;
      });
      const previsionMes = cuotasMes.reduce((sum, cuota) => sum + cuota.cuantia, 0);

      meses.push({
        mes: `${nombresMeses[mes]} ${año}`,
        prevision: previsionMes,
        clientesActivos: cuotasMes.length, // Asumiendo que cada cuota es de un cliente activo
        estado: i === 0 ? 'En curso' : 'Pendiente'
      });
      
      mes++;
    }
    
    return meses;
  };

  // Función para manejar la expansión de filas
  const handleRowClick = (index) => {
    const isRowExpanded = expandedRows.includes(index);
    const newExpandedRows = isRowExpanded
      ? expandedRows.filter(rowIndex => rowIndex !== index)
      : [...expandedRows, index];
    setExpandedRows(newExpandedRows);
  };

  // Función para manejar la expansión de secciones
  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  useEffect(() => {
    fetchPrevisionData();
  }, []);

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="p-6 bg-slate-100 min-h-screen">
      <div className="bg-white rounded-xl shadow-lg p-8 space-y-8">
        {/* Header */}
        <div className="flex items-center justify-between border-b pb-6">
          <h2 className="text-3xl font-bold text-gray-800">Previsión de Pagos</h2>
          <div className="text-sm text-gray-500">
            Actualizado: {new Date().toLocaleDateString('es-ES')}
          </div>
        </div>
        
        {/* Total Pagos Mes Actual */}
        <div className="bg-gradient-to-r from-blue-500 to-blue-600 p-8 rounded-xl border border-blue-400 shadow-lg">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-blue-100 text-sm font-medium mb-1">Total Pagos Mes Actual</p>
              <h3 className="text-3xl font-bold text-white">
                {totalPagosMes.toLocaleString()}€
              </h3>
            </div>
            <div className="p-3 bg-blue-400/30 rounded-lg">
              <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </div>
        </div>

        {/* Tarjetas de resumen */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Previsión Mes Actual */}
          <div className="bg-white rounded-xl p-6 shadow-lg border border-gray-100 hover:shadow-xl transition-all duration-300">
            <div className="flex items-center justify-between mb-4">
              <div className="p-3 bg-indigo-100 rounded-xl">
                <svg className="w-8 h-8 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
              </div>
              <span className="px-3 py-1 text-xs font-medium text-green-600 bg-green-100 rounded-full">+5%</span>
            </div>
            <h3 className="text-lg font-medium text-gray-500">Previsión Mes Actual</h3>
            <p className="text-3xl font-bold text-gray-900 mt-2">{datosMesActual.previsionPagos.toLocaleString()}€</p>
            <div className="mt-4 flex items-center text-sm text-green-600">
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
              </svg>
              <span>5% vs mes anterior</span>
            </div>
          </div>

          {/* Cuotas Impagadas */}
          <div className="bg-white rounded-xl p-6 shadow-lg border border-gray-100 hover:shadow-xl transition-all duration-300">
            <div className="flex items-center justify-between mb-4">
              <div className="p-3 bg-red-100 rounded-xl">
                <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <span className="px-3 py-1 text-xs font-medium text-red-600 bg-red-100 rounded-full">2.4%</span>
            </div>
            <h3 className="text-lg font-medium text-gray-500">Cuotas Impagadas</h3>
            <p className="text-3xl font-bold text-gray-900 mt-2">{datosMesActual.cuotasImpagadas.toLocaleString()}€</p>
            <div className="mt-4 flex items-center text-sm text-red-600">
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
              </svg>
              <span>2.4% del total facturado</span>
            </div>
          </div>

          {/* Clientes Activos */}
          <div className="bg-white rounded-xl p-6 shadow-lg border border-gray-100 hover:shadow-xl transition-all duration-300">
            <div className="flex items-center justify-between mb-4">
              <div className="p-3 bg-emerald-100 rounded-xl">
                <svg className="w-8 h-8 text-emerald-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </div>
              <span className="px-3 py-1 text-xs font-medium text-emerald-600 bg-emerald-100 rounded-full">+3</span>
            </div>
            <h3 className="text-lg font-medium text-gray-500">Clientes Activos</h3>
            <p className="text-3xl font-bold text-gray-900 mt-2">{datosMesActual.numeroClientes}</p>
            <div className="mt-4 flex items-center text-sm text-emerald-600">
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
              </svg>
              <span>3 nuevos este mes</span>
            </div>
          </div>
        </div>

        {/* Sección de Pagos del Mes */}
        <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
          <button 
            onClick={() => toggleSection('pagos')}
            className="w-full p-6 border-b border-gray-200 flex items-center justify-between hover:bg-gray-50 transition-colors group"
          >
            <div className="flex items-center space-x-4">
              <div className="p-2 bg-indigo-100 rounded-lg group-hover:bg-indigo-200 transition-colors">
                <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-800 group-hover:text-indigo-600 transition-colors">
                  Pagos Realizados este Mes
                </h3>
                
              </div>
            </div>
            <svg 
              className={`w-6 h-6 transform transition-transform text-gray-400 group-hover:text-indigo-600 ${expandedSections.pagos ? 'rotate-180' : ''}`} 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          
          {expandedSections.pagos && (
            <div className="overflow-x-auto transition-all duration-300 ease-in-out">
              <table className="min-w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cliente</th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cuantía</th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {pagosMesActual.map((pago, index) => (
                    <React.Fragment key={index}>
                      <tr 
                        onClick={() => handleRowClick(index)} 
                        className="hover:bg-gray-50 transition-colors cursor-pointer"
                      >
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <div className="text-sm font-medium text-gray-900">
                              {pago.nombre} {pago.apellidos}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <span className="text-sm font-semibold text-gray-900">
                            {pago.cuantia.toLocaleString()}€
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          <span className="text-sm text-gray-500">
                            {new Date(pago.fecha).toLocaleDateString('es-ES')}
                          </span>
                        </td>
                      </tr>
                      {expandedRows.includes(index) && (
                        <tr>
                          <td colSpan="3">
                            <div className="px-6 py-4 bg-gray-50 border-t border-b border-gray-200">
                              <div className="animate-fadeIn space-y-3">
                                <h4 className="font-medium text-gray-900">Detalles del Pago</h4>
                                <div className="grid grid-cols-2 gap-4 text-sm">
                                  <div>
                                    <p className="text-gray-500">Método de pago</p>
                                    <p className="font-medium">Transferencia bancaria</p>
                                  </div>
                                  <div>
                                    <p className="text-gray-500">Estado</p>
                                    <p className="font-medium">Completado</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Sección de Previsión 12 Meses */}
        <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
          <button 
            onClick={() => toggleSection('prevision')}
            className="w-full p-6 border-b border-gray-200 flex items-center justify-between hover:bg-gray-50 transition-colors group"
          >
            <div className="flex items-center space-x-4">
              <div className="p-2 bg-purple-100 rounded-lg group-hover:bg-purple-200 transition-colors">
                <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-800 group-hover:text-purple-600 transition-colors">
                  Previsión Próximos 12 Meses
                </h3>
                
              </div>
            </div>
            <svg 
              className={`w-6 h-6 transform transition-transform text-gray-400 group-hover:text-purple-600 ${expandedSections.prevision ? 'rotate-180' : ''}`} 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          
          {expandedSections.prevision && (
            <div className="overflow-x-auto transition-all duration-300 ease-in-out">
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mes</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Previsión</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Clientes Activos</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {datosMeses.map((mes, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">{mes.mes}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{mes.prevision.toLocaleString()}€</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {mes.clientesActivos}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          mes.estado === 'En curso' 
                            ? 'bg-green-100 text-green-800'
                            : 'bg-gray-100 text-gray-800'
                        }`}>
                          {mes.estado}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Nueva sección de Histórico de Pagos */}
        <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
          <button 
            onClick={() => toggleSection('historico')}
            className="w-full p-6 border-b border-gray-200 flex items-center justify-between hover:bg-gray-50 transition-colors group"
          >
            <div className="flex items-center space-x-4">
              <div className="p-2 bg-amber-100 rounded-lg group-hover:bg-amber-200 transition-colors">
                <svg className="w-6 h-6 text-amber-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-800 group-hover:text-amber-600 transition-colors">
                  Histórico de Pagos (12 meses)
                </h3>
              </div>
            </div>
            <svg 
              className={`w-6 h-6 transform transition-transform text-gray-400 group-hover:text-amber-600 ${expandedSections.historico ? 'rotate-180' : ''}`} 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          
          {expandedSections.historico && (
            <div className="divide-y divide-gray-200">
              <table className="min-w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mes</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Total Pagos</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Nº de Pagos</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {pagosHistoricos.map((grupoMes) => (
                    <tr 
                      key={grupoMes.mesKey} 
                      className="hover:bg-gray-50 transition-colors"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm font-medium text-gray-900 capitalize">
                          {grupoMes.nombreMes}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <span className="text-sm font-semibold text-gray-900">
                          {grupoMes.totalMes.toLocaleString()}€
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <span className="text-sm text-gray-500">
                          {grupoMes.pagos.length}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Prevision;
