import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../utils/supabaseClient';
import Actividades from '../../components/Actividades';
import { AuthContext } from '../../contexts/AuthContext';
import Select from 'react-select';


const LeadDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [lead, setLead] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    telefono: '',
    provincia: '',
    bancoHipoteca: '',
    ingresosBrutosAnuales: '',
    pagoMensualHipoteca: '',
    cantidadPorPagar: '',
    anosFaltantes: '',
    interesAnual: '',
    cuotaHace4Anos: '',
    ingresosHace4Anos: '',
    discapacidad: '',
    mayor60: '',
    dificultadesPago: '',
    grupoFamiliar: '',
    ingresosNetosAnuales: '',
    plazoOriginalHipoteca: '',
    cuotaJunio2022: '',
    Carencia: '',
    NuevaCuota: '',
    InteresCarencia: '',
    estadoLead: '',
    prevision_primerpago: '', // Nueva columna añadida
    fase_comercial: '',
    canal_marketing: '',
    ingresosBrutosMensuales: '',
    tipocbp: '',
  });

  const [expandedSections, setExpandedSections] = useState({
    'Datos Hipoteca': false,
    'Ingresos': false,
    'Resultados Simulador': false,
    'Informacion Vulnerable': false,
  });

  const [isEditingEstado, setIsEditingEstado] = useState(false);
  const [isEditingPrevision, setIsEditingPrevision] = useState(false);
  const [isEditingFaseComercial, setIsEditingFaseComercial] = useState(false);
  const [isEditingTipoCBP, setIsEditingTipoCBP] = useState(false);
  const [isEditingPagoMensual, setIsEditingPagoMensual] = useState(false);
  const [isEditingIngresosBrutos, setIsEditingIngresosBrutos] = useState(false);
  const [tempPagoMensual, setTempPagoMensual] = useState('');
  const [tempIngresosBrutos, setTempIngresosBrutos] = useState('');

  const [copySuccess, setCopySuccess] = useState({
    email: false,
    telefono: false
  });

  const [showFormConversion, setShowFormConversion] = useState(false);
  const [selectedCarencia, setSelectedCarencia] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const estados = [
    "Lead Nuevo",
    "Intento de contacto",
    "Mal momento",
    "Lead Simulador en curso",
    "Lead Simulador completado",
    "Lead Pricing",
    "Lead No cumple requisitos",
    "Cliente Primeros Pasos",
    "Cliente Subir Doc",
    "Cliente Firmar Documentación",
    "Cliente Como Va mi Caso",
    "Cliente Caso Resuelto",
    "Cliente solicitud enviada",
    "Recordatorio al banco",
    "Reclamación propuesta",
    "Reclamación BDE",
    "Revisión notaria/acuerdo",
    "Firma notaría/acuerdo",
    "Caso Resuelto"
  ];

  const previsionOptions = [
    { value: '99', label: '99' },
    { value: '249', label: '249' },
    { value: '499', label: '499' },
    { value: '999', label: '999' }
  ];

  const estadoOptions = estados.map(estado => ({
    value: estado,
    label: estado
  }));

  const tipoCBPOptions = [
    { value: 'carencia1', label: 'Carencia 1 año' },
    { value: 'carencia2o5', label: 'Carencia 2 o 5 años' }
  ];

  const { user } = useContext(AuthContext);
  const [userData, setUserData] = useState({ nombre: '', apellidos: '' });

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const { data, error } = await supabase
          .from('usuarios')
          .select('nombre, apellidos')
          .eq('user_id', user.id)
          .single();

        if (error) {
          console.error('Error al obtener datos del usuario:', error);
        } else {
          setUserData(data);
        }
      };

      fetchUserData();
    }
  }, [user]);

  useEffect(() => {
    const fetchLead = async () => {
      try {
        const cleanedId = id.trim();
        console.log(`Buscando por cleanedId: '${cleanedId}'`);

        let { data: simulacionData, error: simulacionError } = await supabase
          .from('leads')
          .select('*')
          .eq('id', cleanedId)
          .single();

        if (simulacionError) {
          throw new Error('Error fetching lead from simulacion_resultados');
        }

        let { data: customerJourneyData, error: customerJourneyError } = await supabase
          .from('customer_journey')
          .select('estado, prevision_primerpago')
          .eq('user_id', cleanedId)
          .single();

        if (customerJourneyError) {
          console.warn('No corresponding entry in customer_journey for user_id:', cleanedId);
          setFormData({ ...simulacionData, estadoLead: 'Desconocido', prevision_primerpago: '' });
        } else {
          setFormData({ ...simulacionData, estadoLead: customerJourneyData.estado, prevision_primerpago: customerJourneyData.prevision_primerpago });
        }

        setLead(simulacionData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchLead();
  }, [id]);

  const toggleSection = (section) => {
    setExpandedSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const handleEstadoChange = async (selectedOption) => {
    const newEstado = selectedOption.value;
    setFormData({ ...formData, estadoLead: newEstado });

    const { error } = await supabase
      .from('customer_journey')
      .upsert({ user_id: id, estado: newEstado }, { onConflict: 'user_id' });

    if (error) {
      console.error('Error updating estado:', error.message);
    } else {
      console.log('Estado updated successfully');
    }

    setIsEditingEstado(false);
  };

  const handlePrevisionChange = async (selectedOption) => {
    const newPrevision = selectedOption.value;
    setFormData({ ...formData, prevision_primerpago: newPrevision });

    const { error } = await supabase
      .from('customer_journey')
      .upsert({ user_id: id, prevision_primerpago: newPrevision }, { onConflict: 'user_id' });

    if (error) {
      console.error('Error updating prevision_primerpago:', error.message);
    } else {
      console.log('Prevision Primer Pago updated successfully');
    }

    setIsEditingPrevision(false);
  };

  const toggleEditEstado = () => {
    setIsEditingEstado(!isEditingEstado);
  };

  const toggleEditPrevision = () => {
    setIsEditingPrevision(!isEditingPrevision);
  };

  const toggleEditFaseComercial = () => {
    setIsEditingFaseComercial(!isEditingFaseComercial);
  };

  const handleFaseComercialChange = async (selectedOption) => {
    const newFase = selectedOption.value;
    setFormData({ ...formData, fase_comercial: newFase });

    const { error } = await supabase
      .from('leads')
      .update({ fase_comercial: newFase })
      .eq('id', id);

    if (error) {
      console.error('Error updating fase_comercial:', error.message);
    } else {
      console.log('Fase comercial updated successfully');
    }

    setIsEditingFaseComercial(false);
  };

  const handleCopy = async (text, field) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(prev => ({ ...prev, [field]: true }));
      
      // Reset el estado después de 2 segundos
      setTimeout(() => {
        setCopySuccess(prev => ({ ...prev, [field]: false }));
      }, 2000);
    } catch (err) {
      console.error('Error al copiar:', err);
    }
  };

  const handleTipoCBPChange = async (selectedOption) => {
    const newTipoCBP = selectedOption.value;
    setFormData({ ...formData, tipocbp: newTipoCBP });

    const { error } = await supabase
      .from('leads')
      .update({ tipocbp: newTipoCBP })
      .eq('id', id);

    if (error) {
      console.error('Error updating tipocbp:', error.message);
    } else {
      console.log('Tipo CBP updated successfully');
    }

    setIsEditingTipoCBP(false);
  };

  const toggleEditTipoCBP = () => {
    setIsEditingTipoCBP(!isEditingTipoCBP);
  };

  const getTipoCBPLabel = (value) => {
    const option = tipoCBPOptions.find(opt => opt.value === value);
    return option ? option.label : '-';
  };

  const handlePagoMensualChange = async (e) => {
    const newValue = e.target.value;
    setTempPagoMensual(newValue);
  };

  const handleIngresosBrutosChange = async (e) => {
    const newValue = e.target.value;
    setTempIngresosBrutos(newValue);
  };

  const handlePagoMensualSubmit = async () => {
    const numericValue = parseFloat(tempPagoMensual);
    if (!isNaN(numericValue)) {
      const { error } = await supabase
        .from('leads')
        .update({ pagoMensualHipoteca: numericValue })
        .eq('id', id);

      if (error) {
        console.error('Error updating pagoMensualHipoteca:', error.message);
      } else {
        setFormData({ ...formData, pagoMensualHipoteca: numericValue });
      }
    }
    setIsEditingPagoMensual(false);
  };

  const handleIngresosBrutosSubmit = async () => {
    const numericValue = parseFloat(tempIngresosBrutos);
    if (!isNaN(numericValue)) {
      const { error } = await supabase
        .from('leads')
        .update({ ingresosBrutosMensuales: numericValue })
        .eq('id', id);

      if (error) {
        console.error('Error updating ingresosBrutosMensuales:', error.message);
      } else {
        setFormData({ ...formData, ingresosBrutosMensuales: numericValue });
      }
    }
    setIsEditingIngresosBrutos(false);
  };

  const carenciaOptions = [
    { value: 'carencia2o5', label: 'Carencia 2 o 5 años' },
    { value: 'carencia1', label: 'Carencia 1 año' }
  ];

  const getPlanOptions = (carencia) => {
    if (carencia === 'carencia2o5') {
      return [
        { 
          value: 'pro', 
          label: 'Pro',
          honorarios: 1489,
          pagoInicial: 499,
          pagoMensual: 99,
          cuotas: 10
        },
        { 
          value: 'premium', 
          label: 'Premium',
          honorarios: 999,
          pagoInicial: 999,
          pagoMensual: 0,
          cuotas: 0
        },
        { 
          value: 'basic', 
          label: 'Basic',
          honorarios: 1485,
          pagoInicial: 99,
          pagoMensual: 99,
          cuotas: 14
        }
      ];
    } else if (carencia === 'carencia1') {
      return [
        { 
          value: 'pro', 
          label: 'Pro',
          honorarios: 793,
          pagoInicial: 199,
          pagoMensual: 99,
          cuotas: 6
        },
        { 
          value: 'premium', 
          label: 'Premium',
          honorarios: 499,
          pagoInicial: 499,
          pagoMensual: 0,
          cuotas: 0
        },
        { 
          value: 'basic', 
          label: 'Basic',
          honorarios: 1485,
          pagoInicial: 99,
          pagoMensual: 99,
          cuotas: 14
        }
      ];
    }
    return [];
  };

  const handleCarenciaChange = (selectedOption) => {
    setSelectedCarencia(selectedOption);
    setSelectedPlan(null);
  };

  const handlePlanChange = (selectedOption) => {
    setSelectedPlan(selectedOption);
  };

  const handleConvertirCliente = async () => {
    if (!selectedCarencia || !selectedPlan) {
      alert('Por favor, complete todos los campos');
      return;
    }

    try {
      // 1. Crear cliente con los datos del lead
      const clienteData = {
        id: id,
        nombre: formData.nombre,
        apellidos: formData.apellidos,
        telefono: formData.telefono,
        email: formData.email,
        provincia: formData.provincia,
        domicilio: formData.domicilio,
        created_at: new Date(),
        estado_civil: formData.estado_civil || null,
        bancoHipoteca: formData.bancoHipoteca || null,
        precio_vivienda: formData.precio_vivienda || null,
        ingresosBrutosAnuales: formData.ingresosBrutosAnuales || null,
        ingresosNetosAnuales: formData.ingresosNetosAnuales || null,
        pagoMensualHipoteca: formData.pagoMensualHipoteca || null,
        vivienda_habitual: formData.vivienda_habitual || null,
        fecha_firma_hipoteca: formData.fecha_firma || null,
        Carencia: selectedCarencia.label
      };

      const { error: clienteError } = await supabase
        .from('clientes')
        .upsert([clienteData], { onConflict: 'id' });

      if (clienteError) throw clienteError;

      // 2. Insertar en plan_honorarios
      const { error: planError } = await supabase
        .from('plan_honorarios')
        .insert([{
          user_id: id,
          duracion_carencia: selectedCarencia.label,
          total_honorarios: selectedPlan.honorarios,
          tipo_plan: selectedPlan.label,
          pago_inicial: selectedPlan.pagoInicial,
          pago_mensual: selectedPlan.pagoMensual,
          nr_de_cuotas: selectedPlan.cuotas,
          created_at: new Date()
        }]);

      if (planError) throw planError;

      // 3. Actualizar customer_journey
      const { error: journeyError } = await supabase
        .from('customer_journey')
        .upsert([{
          user_id: id,
          estado: 'Cliente Primeros Pasos',
          timestamp: new Date(),
          updated_at: new Date()
        }], { onConflict: 'user_id' });

      if (journeyError) throw journeyError;

      // 4. Insertar cuotas si existen
      if (selectedPlan.cuotas > 0) {
        const cuotas = [];
        const currentDate = new Date();
        
        for (let i = 0; i < selectedPlan.cuotas; i++) {
          const fechaPrevista = new Date(currentDate);
          fechaPrevista.setMonth(currentDate.getMonth() + i + 1);
          
          cuotas.push({
            cliente_id: id,
            cuantia: selectedPlan.pagoMensual,
            fecha_prevista: fechaPrevista.toISOString().split('T')[0],
            estado: 'pendiente'
          });
        }

        const { error: cuotasError } = await supabase
          .from('cuotas')
          .insert(cuotas);

        if (cuotasError) throw cuotasError;
      }

      alert('Lead convertido a cliente exitosamente');
      setShowFormConversion(false);
      setSelectedCarencia(null);
      setSelectedPlan(null);
      
      // Redirigir a la página de clientes
      navigate('/clientes');

    } catch (error) {
      console.error('Error al convertir lead a cliente:', error);
      alert('Error al convertir lead a cliente. Por favor, intente nuevamente.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const excludedFields = [
    'user_id',
    'precioVivienda',
    'firmasteHipoteca',
    'viviendaHabitual',
    'politicaPrivacidad',
    'created_at',
    'id',
    'usuario_asignado',
  ];

  const friendlyFieldNames = {
    bancoHipoteca: 'Banco donde tiene la hipoteca',
    ingresosBrutosAnuales: 'Ingresos brutos anuales',
    discapacidad: '¿Tiene a alguien con discapacidad?',
    mayor60: '¿Es mayor de 60 años?',
    dificultadesPago: '¿Ha tenido dificultades para pagar la hipoteca?',
    grupoFamiliar: '¿Pertenece a un grupo familiar vulnerable?',
    pagoMensualHipoteca: 'Cuota mensual hipoteca',
    cantidadPorPagar: 'Cantidad que le falta por pagar de la hipoteca',
    anosFaltantes: 'Cuantos años faltan para pagar la hipoteca',
    interesAnual: 'El interes anual',
    cuotaHace4Anos: 'Cuota de la hipoteca hace 4 años',
    ingresosHace4Anos: 'Ingresos netos hace 4 años',
    provincia: 'Provincia',
    ingresosNetosAnuales: 'Ingresos netos anuales',
    plazoOriginalHipoteca: 'Plazo original de la hipoteca',
    cuotaJunio2022: 'Cuota en junio 2022',
    Carencia: 'Carencia',
    NuevaCuota: 'Nueva Cuota',
    InteresCarencia: 'Interes Carencia',
    estadoLead: 'Estado lead',
    prevision_primerpago: 'Previsión Primer Pago', // Nueva columna añadida
  };

  const categories = {
    'Datos Hipoteca': [
      'bancoHipoteca', 'pagoMensualHipoteca', 'cantidadPorPagar', 'anosFaltantes', 'interesAnual',
      'cuotaHace4Anos', 'plazoOriginalHipoteca', 'cuotaJunio2022'
    ],
    'Ingresos': [
      'ingresosBrutosAnuales', 'ingresosNetosAnuales', 'ingresosHace4Anos'
    ],
    'Resultados Simulador': [
      'Carencia', 'NuevaCuota', 'InteresCarencia'
    ],
    'Informacion Vulnerable': [
      'discapacidad', 'mayor60', 'dificultadesPago', 'grupoFamiliar'
    ]
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '300px',
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      width: '300px',
      zIndex: 9999,
      position: 'absolute',
      backgroundColor: 'white',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999
    }),
    container: (provided) => ({
      ...provided,
      position: 'relative',
    })
  };

  return (
    <div className="container mx-auto px-4 py-6 max-w-7xl">
      {/* Navegación y breadcrumbs */}
      <div className="mb-6">
        <div className="flex items-center space-x-2 text-sm text-gray-500">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
            onClick={() => navigate('/leads')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            Volver a Leads
          </button>
          <span>•</span>
          <span>Detalles del Lead</span>
        </div>
      </div>

      {/* Header con información principal */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 mb-6">
        <div className="p-6 sm:p-8 bg-gradient-to-r from-blue-50 via-indigo-50 to-blue-50">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="bg-gradient-to-r from-blue-600 to-indigo-600 p-3 rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{formData.nombre} {formData.apellidos}</h1>
                <p className="text-sm text-gray-500">ID: {id}</p>
              </div>
            </div>
            <div className="mt-4 sm:mt-0 flex flex-col items-end gap-2">
              <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                formData.estadoLead === 'Activo' ? 'bg-green-100 text-green-800' :
                formData.estadoLead === 'Pendiente' ? 'bg-yellow-100 text-yellow-800' :
                'bg-gray-100 text-gray-800'
              }`}>
                {formData.estadoLead || 'Sin estado'}
              </span>
              <button
                onClick={() => setShowFormConversion(true)}
                className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white text-sm font-medium rounded-lg transition-all duration-200 shadow-sm hover:shadow-md"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
                Convertir en Cliente
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de conversión */}
      {showFormConversion && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl shadow-xl transform transition-all w-full max-w-lg">
            {/* Header del modal */}
            <div className="px-6 py-4 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-semibold text-gray-900">Convertir Lead a Cliente</h3>
                <button
                  onClick={() => setShowFormConversion(false)}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>

            {/* Contenido del modal */}
            <div className="px-6 py-4">
              <div className="space-y-4">
                {/* Duración carencia */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Duración de la carencia
                  </label>
                  <Select
                    options={carenciaOptions}
                    onChange={handleCarenciaChange}
                    value={selectedCarencia}
                    placeholder="Selecciona la duración"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>

                {/* Tipo de Plan */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Plan de pago
                  </label>
                  <Select
                    options={selectedCarencia ? getPlanOptions(selectedCarencia.value) : []}
                    onChange={handlePlanChange}
                    value={selectedPlan}
                    isDisabled={!selectedCarencia}
                    placeholder="Selecciona el plan"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>

                {/* Resumen del plan seleccionado */}
                {selectedPlan && (
                  <div className="mt-4 bg-indigo-50 rounded-lg p-4 border border-indigo-100">
                    <h4 className="text-sm font-medium text-indigo-800 mb-2">Resumen del plan</h4>
                    <div className="grid grid-cols-2 gap-3 text-sm">
                      <div>
                        <span className="text-gray-600">Total Honorarios:</span>
                        <span className="ml-1 font-medium text-gray-900">{selectedPlan.honorarios}€</span>
                      </div>
                      <div>
                        <span className="text-gray-600">Pago Inicial:</span>
                        <span className="ml-1 font-medium text-gray-900">{selectedPlan.pagoInicial}€</span>
                      </div>
                      <div>
                        <span className="text-gray-600">Pago Mensual:</span>
                        <span className="ml-1 font-medium text-gray-900">{selectedPlan.pagoMensual}€</span>
                      </div>
                      <div>
                        <span className="text-gray-600">Número de cuotas:</span>
                        <span className="ml-1 font-medium text-gray-900">{selectedPlan.cuotas}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Footer del modal */}
            <div className="px-6 py-4 bg-gray-50 rounded-b-xl border-t border-gray-200">
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowFormConversion(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleConvertirCliente}
                  disabled={!selectedCarencia || !selectedPlan}
                  className={`px-4 py-2 text-sm font-medium text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                    (!selectedCarencia || !selectedPlan)
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700'
                  }`}
                >
                  Confirmar Conversión
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Grid de información principal */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
        {/* Información de contacto */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
              </svg>
              Información de contacto
            </h2>
            <div className="space-y-4">
              {/* Email con botón de copiar */}
              <div className="group">
                <label className="text-sm font-medium text-gray-500">Email</label>
                <div className="mt-1 flex items-center">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center bg-gray-50 rounded-lg p-2 hover:bg-gray-100 transition-colors">
                      <span className="text-gray-900">{formData.email}</span>
                      <button
                        onClick={() => handleCopy(formData.email, 'email')}
                        className="ml-2 p-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        {copySuccess.email ? (
                          <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        ) : (
                          <svg className="h-5 w-5 text-gray-400 hover:text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Teléfono con botón de copiar */}
              <div className="group">
                <label className="text-sm font-medium text-gray-500">Teléfono</label>
                <div className="mt-1 flex items-center">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center bg-gray-50 rounded-lg p-2 hover:bg-gray-100 transition-colors">
                      <span className="text-gray-900">{formData.telefono}</span>
                      <button
                        onClick={() => handleCopy(formData.telefono, 'telefono')}
                        className="ml-2 p-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        {copySuccess.telefono ? (
                          <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        ) : (
                          <svg className="h-5 w-5 text-gray-400 hover:text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Provincia */}
              <div>
                <label className="text-sm font-medium text-gray-500">Provincia</label>
                <div className="mt-1 bg-gray-50 rounded-lg p-2">
                  <span className="text-gray-900">{formData.provincia}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Información financiera */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Información financiera
            </h2>
            <div className="space-y-4">
              {/* Cuota mensual */}
              <div>
                <label className="text-sm font-medium text-gray-500">Cuota mensual</label>
                {isEditingPagoMensual ? (
                  <div className="mt-1">
                    <div className="relative rounded-lg shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">€</span>
                      </div>
                      <input
                        type="number"
                        value={tempPagoMensual}
                        onChange={handlePagoMensualChange}
                        className="block w-full pl-7 pr-12 py-2 rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="0.00"
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') handlePagoMensualSubmit();
                        }}
                        autoFocus
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-2">
                        <button
                          onClick={handlePagoMensualSubmit}
                          className="p-1 rounded-md text-blue-600 hover:text-blue-700 hover:bg-blue-50 transition-colors"
                          title="Guardar"
                        >
                          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        </button>
                        <button
                          onClick={() => setIsEditingPagoMensual(false)}
                          className="p-1 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-50 transition-colors"
                          title="Cancelar"
                        >
                          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <p className="mt-1 text-xs text-gray-500">Presiona Enter o el botón de check para guardar</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setIsEditingPagoMensual(true);
                      setTempPagoMensual(formData.pagoMensualHipoteca?.toString() || '');
                    }}
                    className="mt-1 bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-colors cursor-pointer group relative"
                  >
                    <div className="flex items-center justify-between">
                      <span className="text-lg font-medium text-gray-900">
                        {formData.pagoMensualHipoteca ? `${formData.pagoMensualHipoteca}€` : '-'}
                      </span>
                      <div className="opacity-0 group-hover:opacity-100 transition-opacity">
                        <span className="bg-blue-50 text-blue-600 px-2 py-1 rounded-md text-xs font-medium">
                          Click para editar
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Ingresos Brutos Mensuales */}
              <div>
                <label className="text-sm font-medium text-gray-500">Ingresos Brutos Mensuales</label>
                {isEditingIngresosBrutos ? (
                  <div className="mt-1">
                    <div className="relative rounded-lg shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">€</span>
                      </div>
                      <input
                        type="number"
                        value={tempIngresosBrutos}
                        onChange={handleIngresosBrutosChange}
                        className="block w-full pl-7 pr-12 py-2 rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="0.00"
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') handleIngresosBrutosSubmit();
                        }}
                        autoFocus
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-2">
                        <button
                          onClick={handleIngresosBrutosSubmit}
                          className="p-1 rounded-md text-blue-600 hover:text-blue-700 hover:bg-blue-50 transition-colors"
                          title="Guardar"
                        >
                          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        </button>
                        <button
                          onClick={() => setIsEditingIngresosBrutos(false)}
                          className="p-1 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-50 transition-colors"
                          title="Cancelar"
                        >
                          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <p className="mt-1 text-xs text-gray-500">Presiona Enter o el botón de check para guardar</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setIsEditingIngresosBrutos(true);
                      setTempIngresosBrutos(formData.ingresosBrutosMensuales?.toString() || '');
                    }}
                    className="mt-1 bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-colors cursor-pointer group relative"
                  >
                    <div className="flex items-center justify-between">
                      <span className="text-lg font-medium text-gray-900">
                        {formData.ingresosBrutosMensuales ? `${formData.ingresosBrutosMensuales}€` : '-'}
                      </span>
                      <div className="opacity-0 group-hover:opacity-100 transition-opacity">
                        <span className="bg-blue-50 text-blue-600 px-2 py-1 rounded-md text-xs font-medium">
                          Click para editar
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Tipo de Carencia */}
              <div>
                <label className="text-sm font-medium text-gray-500">Tipo de Carencia</label>
                <div className="mt-1">
                  {isEditingTipoCBP ? (
                    <Select
                      options={tipoCBPOptions}
                      value={tipoCBPOptions.find(option => option.value === formData.tipocbp)}
                      onChange={handleTipoCBPChange}
                      styles={customStyles}
                      className="mt-1"
                      placeholder="Seleccionar tipo de carencia"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                    />
                  ) : (
                    <div
                      onClick={toggleEditTipoCBP}
                      className="bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-colors cursor-pointer group"
                    >
                      <div className="flex items-center justify-between">
                        <span className="text-lg font-medium text-gray-900">
                          {getTipoCBPLabel(formData.tipocbp)}
                        </span>
                        <svg
                          className="h-5 w-5 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Estado y seguimiento */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
              </svg>
              Estado y seguimiento
            </h2>
            <div className="space-y-4">
              {/* Estado Lead */}
              <div>
                <label className="text-sm font-medium text-gray-500">Estado Lead</label>
                <div className="mt-1">
                  {isEditingEstado ? (
                    <Select
                      options={estadoOptions}
                      value={{ value: formData.estadoLead, label: formData.estadoLead }}
                      onChange={handleEstadoChange}
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                    />
                  ) : (
                    <div
                      onClick={toggleEditEstado}
                      className="bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-colors cursor-pointer group"
                    >
                      <div className="flex items-center justify-between">
                        <span className="text-lg font-medium text-gray-900">
                          {formData.estadoLead || '-'}
                        </span>
                        <svg
                          className="h-5 w-5 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Fase Comercial */}
              <div>
                <label className="text-sm font-medium text-gray-500">Fase Comercial</label>
                <div className="mt-1">
                  {isEditingFaseComercial ? (
                    <Select
                      options={[
                        { value: 'Nuevo', label: 'Nuevo' },
                        { value: 'Interesado', label: 'Interesado' },
                        { value: 'Mal Momento', label: 'Mal Momento' },
                        { value: 'No contesta', label: 'No contesta' },
                        { value: 'Telefono incorrecto', label: 'Telefono incorrecto' },
                        { value: 'No tiene perfil', label: 'No tiene perfil' },
                        { value: 'No interesado', label: 'No interesado' },
                        { value: 'Contratado', label: 'Contratado' }
                      ]}
                      value={{ value: formData.fase_comercial, label: formData.fase_comercial }}
                      onChange={handleFaseComercialChange}
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                    />
                  ) : (
                    <div
                      onClick={toggleEditFaseComercial}
                      className="bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-colors cursor-pointer group"
                    >
                      <div className="flex items-center justify-between">
                        <span className="text-lg font-medium text-gray-900">
                          {formData.fase_comercial || '-'}
                        </span>
                        <svg
                          className="h-5 w-5 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Canal Marketing */}
              <div>
                <label className="text-sm font-medium text-gray-500">Canal Marketing</label>
                <div className="mt-1 bg-gray-50 rounded-lg p-3">
                  <span className="text-lg font-medium text-gray-900">
                    {formData.canal_marketing || '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Actividades */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Actividades
          </h2>
          <Actividades leadId={id} clienteId={null} hideExpediente={true} />
        </div>
      </div>
    </div>
  );
};

export default LeadDetalle;
