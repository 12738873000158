import React from 'react';
import { Link } from 'react-router-dom';

const Informes = () => {
  return (
    <div className="p-6">
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-bold mb-6">Informes</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Link 
            to="/informes/impagados"
            className="p-6 border rounded-lg hover:bg-gray-50"
          >
            <h3 className="text-xl font-semibold mb-2">Gestión de Impagados</h3>
            <p className="text-gray-600">Gestiona y monitoriza los pagos atrasados de clientes.</p>
          </Link>
          
          <Link 
            to="/informes/prevision"
            className="p-6 border rounded-lg hover:bg-gray-50"
          >
            <h3 className="text-xl font-semibold mb-2">Previsión de Pagos</h3>
            <p className="text-gray-600">Visualiza y analiza las previsiones de pagos futuros.</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Informes;
